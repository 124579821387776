import { Button, Modal, Text } from '@nextui-org/react'
import { Checkbox, Empty, message } from 'antd'
import React, { Fragment, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { userStore } from '../../store/userStore'
import authQueries from '../../queries/authQueries'
import { Pagination, PaginationItem } from '@mui/material'
import ModalHeaderText from '../Common/ModalHeaderText'
import LoaderCommon from '../Common/LoaderCommon'
import { useApi } from '../Common/ApiProvider'
import LeadStatus from '../Common/LeadStatus'
import moment from 'moment'
import ProspectStatusList from '../Common/ProspectStatusList'
import SelectSearch from '../Common/SelectSearch'
import RegisteredCheckSearch from '../Common/RegisteredCheckSearch'
import toast from 'react-hot-toast'

const ApplicantList = () => {

    const navigate = useNavigate()

    const storeData = userStore()
    const [checkID, setCheckID] = useState([]);
    const [visible, setVisible] = useState(false)
    const [result, setResult] = useState([])
    const [page, setPage] = useState(0)
    const [visa, setVisa] = useState(null)
    const [status, setStatus] = useState("")
    const [registered, setRegistered] = useState("ALL")

    const advancedMasterList = authQueries.useAdvancedSearchMutation(
        (response) => {
        }
    );

    useEffect(() => {
        advancedMasterList.mutateAsync({visa: visa})
    }, [])

    const inputSearchArry = [
        {
            label: "Visa Type",
            size: 2,            
            state: visa,
            setState: setVisa,
            input: SelectSearch,
            value: advancedMasterList?.data?.data?.visatype,
            name: 'description',
            simple: true
        },
        {
            label: "Prospect Status",
            size: 2,            
            state: status,
            setState: setStatus,
            input: SelectSearch,
            value: advancedMasterList?.data?.data?.prospectstatus,
            name: 'name',
            simple: true
        },
        {
            label: "Registration Status",
            size: 2,            
            state: registered,
            setState: setRegistered,
            input: RegisteredCheckSearch,
            simple: true
        },
    ]

    const {apiData} = useApi()

    const applicantListing = authQueries.useCounselorWiseListing(
        (response) => {
            setResult(response)
        }
    );

    useEffect(()=> {
        applicantListing.mutateAsync({
            status: status,
            reg: registered,
            visa: visa,
            page: page, 
            size:50,
            id: storeData?.uid?.uid, 
            role: storeData?.uid?.role
        })
    },[page, apiData, visa, status, registered])

    const handleChange1 = (e) => {
        let selected = []
        const {checked} = e.target
        if(checked){
            result?.data?.items?.map(id => selected.push(id.id))
        }
        setCheckID(selected)
    };
  
    const handleChange2 = (e) => {
        const {checked, value} = e.target
        const selectID = parseInt(value)
        setCheckID([...checkID, selectID])
        if(!checked){
            setCheckID(checkID.filter((item) => item !== selectID));
        }
    };
  

    const children = (id) => {
        return (
            <Checkbox
                checked={checkID.find(number => number === id) ? true: false}  
                onChange={handleChange2} 
                value={id} 
            />
        );
    }

    const handler = () => {
        setVisible(true)
    }

    const handleChange = (event, value) => {
        setPage(value-1)
    }


  return (
    <div className="prg-coordinator">

        <div className="breadcrumb-and-otherbuttons">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">
                        <button onClick={()=>{navigate(-1)}}>
                            User List
                        </button>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">Leads</li>
                </ol>
            </nav>
        </div>
    
        <div id="accordion" className="add_clients_accordion_form mt-4">
            <div className="card" id="appointments_wrpr">
                <div className="card-header">
                    <a className="collapsed card-link" href="" style={{ pointerEvents: 'none' }}>
                        My Clients
                    </a>
                </div>
                <div id="appointment_any" className="collapse show" data-parent="#accordion">
                    <div className="card-body">
                        <div className="grid grid-cols-12 max-[1389px]:grid-cols-10 max-[1163px]:grid-cols-8 max-[929px]:grid-cols-6 max-[585px]:grid-cols-4 max-[445px]:grid-cols-2 gap-x-6 gap-y-5 !mb-5">
                            {
                                inputSearchArry?.map((item, index)=> (
                                    <Fragment>
                                        {
                                            item?.simple &&
                                            <div key={index} className={`col-span-${item?.size} flex flex-col gap-2`}>
                                                <item.input item={item} />
                                            </div>
                                        }
                                    </Fragment>
                                ))
                            }
                        </div>
                        <div className="search-results-count-wrpr">
                            {
                                result?.data?.totalItems > 0 ?
                                `Search Results ${result?.data?.currentPage * 25 + 1}-${result?.data?.currentPage * 25 + result?.data?.items?.length} of ${result?.data?.totalItems}` :
                                `O results found`
                            }
                        </div>
                        {
                            applicantListing?.isLoading ?
                            <div className='my-10' style={{display:'flex', justifyContent:'center', flexDirection:'column'}}>
                                <LoaderCommon />
                            </div>:
                            <Fragment>
                                {result?.data?.totalPages > 0 ?
                                    <div className="table-wrpr">
                                        <div className="table-section">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <table className="table prospect-table">
                                                        <thead>
                                                            <tr>
                                                                <th width="2%">
                                                                    <div style={{marginLeft:'10px'}}>
                                                                        <Checkbox
                                                                        checked={result.data?.items?.length === checkID.length}
                                                                        indeterminate={result?.data?.items?.length !== checkID.length && checkID.length > 0}
                                                                        onChange={handleChange1} 
                                                                        />
                                                                    </div>
                                                                </th>
                                                                <th>Profile Status</th>
                                                                <th>Applicant Name</th>
                                                                <th>Email</th>
                                                                <th>Mobile</th>
                                                                <th>Visa Type</th>
                                                                <th>Prospect Status</th>
                                                                <th>Registered Status</th>
                                                                <th>Office</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="ui-sortable">
                                                        {
                                                            result?.data?.items?.map((item,index) => (
                                                            <tr key={index} className="ui-sortable-handle">
                                                                <th>
                                                                    <div style={{marginLeft:'10px'}}>
                                                                        {children(item?.id)}
                                                                    </div>
                                                                </th>
                                                                <td><LeadStatus item={item} /></td>
                                                                <td>{item?.applicant_name} {item?.middlename} {item?.lastname}</td>
                                                                <td>{item?.email}</td>
                                                                <td>
                                                                    <div className="email-mobile flex flex-col">
                                                                        <div>{item.mobile_no}</div>
                                                                        <div>{item.landline_no}</div>
                                                                        <div>{item.whatsapp_no}</div>
                                                                    </div>
                                                                </td>
                                                                <td>{item?.visatype?.country?.icon} {item?.visatype?.description}</td>
                                                                <td>{item?.status_of_prospect?.name}</td>
                                                                <td>{item?.registered_date ? <span className="registered">{moment(item?.registered_date).format('DD-MM-YYYY')}</span> : '-'}</td>                                                            
                                                                <td>{item?.office?.name}</td>
                                                            </tr>
                                                        ))}                                        
                                                        </tbody>
                                                    </table>
                                                    {
                                                        (result?.data?.totalPages > 1 && checkID.length === 0 )&&
                                                        <ul className="pagination pagination-sm justify-content-center table-pagination mt-5 custom-pagination">
                                                            <Pagination
                                                                count={result?.data?.totalPages}
                                                                renderItem={
                                                                    (item) => (
                                                                        <div>
                                                                        <PaginationItem 
                                                                        component="li"
                                                                        {...item}
                                                                        className={`!bg-white !text-[12px] ${item.selected && '!border-rose-500'}`}
                                                                        />
                                                                        </div>
                                                                    )
                                                                }
                                                                variant="outlined"
                                                                page={result?.data?.currentPage + 1}
                                                                shape="rounded"
                                                                onChange={handleChange}
                                                            />
                                                        </ul>
                                                    }
                                                    {checkID.length > 0 &&
                                                        <ul className='fixed right-4 bottom-4'>
                                                            <button onClick={handler} className='btn-customized-large h-[35px] w-[120px]' >
                                                                <i className="icon-user-add" />&nbsp;&nbsp;Bulk Assign
                                                            </button>
                                                        </ul>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>:
                                    <div className="my-24 text-center flex justify-center">
                                        <Empty description={<div className="text-[14px] font-medium text-blue-700">Sorry! No Leads found.</div>} />
                                    </div>
                                }
                            </Fragment>
                        }
                    </div>
                </div>
            </div>
        </div>

        <ModalAssign visible={visible} setVisible={setVisible} checkID={checkID} setCheckID={setCheckID} />
      
    </div>
  )
}

export default ApplicantList


const ModalAssign = ({visible, setVisible, checkID, setCheckID}) => {

    const storeData = userStore()

    const {setApiDataAndNotify} = useApi()

    const [role, setRole] = useState(null)
    const [user, setUser] = useState(null)

    const closeHandler = () => {
        setVisible(false)
    }

    useEffect(() => {
        if(visible){
            RoleListing.mutateAsync({page:0, size:100})
        }
    },[visible])

    useEffect(() => {
        if(visible){
            UserListing.mutateAsync({page:0, size:100000, role: role, status: 'ACTIVE'})
        }
    },[visible, role])

    const RoleListing = authQueries.useRoleMutationList(
        (response) => {
        }
    );

    const UserListing = authQueries.useUserRoleMutationList(
        (response) => {
        }
    );

    const assign = () => {
        AssignUser.mutateAsync({id: checkID, roleID: role, userID: user, oldID: storeData?.uid?.uid})
    }

    const AssignUser = authQueries.useBulkAssignMutation(
        (response) => {
            setApiDataAndNotify(response)
            setVisible(false)
            setCheckID([])
            if(response?.data > 0){
                toast.success(`Leads Assigned Successfully`)
            }else{
                message.error('User Not Assigned')
            }
        }
    );


    return (
        <Modal
            open={visible}
            onClose={closeHandler}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            width="500px"
            scroll
            blur
            closeButton
        >
            <Modal.Header>
                <ModalHeaderText label="Select User" />
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label style={{fontSize:'12px'}} className='label-bulkassign'>Role</label>
                            <select
                            className="bg-white border border-solid border-gray-300 rounded-md w-full h-[44px] pl-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0" 
                            onChange={(e)=>{setRole(e.target.value)}} 
                            >
                                <option value="">Select Role</option>
                                {
                                    RoleListing?.data?.data?.items?.map((item,index) => (
                                        <Fragment>
                                            {
                                                (item?.id === 3 || item?.id === 5 || item?.id === 6 || item?.id === 7 || item?.id === 8 || item?.id === 9) &&
                                                <option key={index} value={item?.id}>{item?.label}</option>
                                            }
                                        </Fragment>
                                    ))
                                }
                            </select>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group">
                            <label style={{fontSize:'12px'}} className='label-bulkassign'>Users</label>
                            <select 
                            onChange={(e)=>{setUser(e.target.value)}} 
                            className="bg-white border border-solid border-gray-300 rounded-md w-full h-[44px] pl-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0" 
                            >
                                <option value="">Select User</option>
                                {role &&
                                    UserListing?.data?.data?.items?.map((item,index) => {
                                        return(
                                            <Fragment key={index}>
                                                {item?.id !== storeData?.uid?.uid &&
                                                    <option value={item?.id}>{item?.userdetail?.firstname} {item?.userdetail?.lastname}</option>
                                                }
                                            </Fragment>
                                            )
                                        }
                                    )
                                }
                            </select>
                        </div>
                    </div>
                </div>

            </Modal.Body>
            <Modal.Footer>
                <div className='flex justify-end w-full gap-3'>
                    <button 
                    className='h-[35px] w-[100px] bg-red-600 text-white rounded-md'
                    onClick={closeHandler}>
                        Close
                    </button>
                    <button onClick={assign} className="btn-customized-large h-[35px] w-[100px]">
                        Assign
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}
