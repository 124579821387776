/* eslint-disable jsx-a11y/anchor-is-valid */
// import { decode } from 'html-entities'
import React, { Fragment, useEffect, useState } from 'react'
import authQueries from '../../../queries/authQueries';
import { useLoaderStore, usePaginationStore, userStore } from '../../../store/userStore';
import moment from 'moment';
import LoaderCommon from '../../Common/LoaderCommon';
import PaginationComponent from '../../Common/PaginationComponent';
import FollowUp from '../../Common/FollowUp';
import AssignModal from '../../Common/AssignModal';
import LeadStatus from '../../Common/LeadStatus';
import ProspectStatusList from '../../Common/ProspectStatusList';
import ApplicantViewButton from '../../Common/ApplicantViewButton';
import ApplicantEditButton from '../../Common/ApplicantEditButton';
import RegistrationModal from '../../Common/RegistrationModal';
import { useApi } from '../../Common/ApiProvider';
import AdvancedSearch from '../../Common/AdvancedSearch';
import { useAdvancedSearch } from '../../../store/advancedSearchStore';
import { IconClearAll } from '@tabler/icons-react';
import { Empty } from 'antd';
import ApplicationStatusModal from '../../Common/ApplicationStatusModal';
import { decode } from 'html-entities';
import ApplicationStatusCreateModal from '../../Common/ApplicationStatusCreateModal';

const ApplicantListing = () => {

    const [result, setResult] = useState([]);
    const [active, setActive] = useState({ index: null, status: false });
    const user = userStore((state)=> state.user);
    const data = useAdvancedSearch((state) => state);
    const { apiData } = useApi()
    const loader = useLoaderStore((state) => state.loader);
    const setLoader = useLoaderStore((state) => state.setLoader);
    const resetPage = usePaginationStore((state) => state.resetPage);
    const resetAll = useAdvancedSearch((state)=> state.resetAll)
    const currentPage = usePaginationStore((state) => state.currentPage);
    const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);

    const appStatusList = authQueries.useAppStaffStatusMutationList(
        (res)=>{
        }
    )
    useEffect(() => {
        appStatusList.mutateAsync({page: 0, size: 10000})
    }, [])
    
    useEffect(() => {
        setLoader(true)
    }, [data, currentPage]);

    const ApplicantListing = authQueries.useApplicantMutationVisaManagerList(
        (response) => {
            setResult(response)
            setLoader(false)
        }
    );

    const markRegistrationList = authQueries.useMarkRegisterPendingMutation(
        (response) => {
            setResult(response)
            setLoader(false)
        }
    )

    useEffect(() => {
        if(user?.role === "MANAGER"){
            ApplicantListing.mutateAsync({
                page: currentPage,
                size: 25,
                ...data,
            })
        }else{
            markRegistrationList.mutateAsync({
                page: currentPage,
                size: 25,
                ...data,
            })
        }
    }, [data, currentPage, apiData])

    const resetFilters =()=>{
        resetAll()
        resetPage()
    }

    const expantButton = (index) => {
        if (active.index === index) {
            setActive({
                index: null,
                status: false
            })
        } else {
            setActive({
                index: index,
                status: true
            })
        }
    }

    const counselorName =(item)=> {
        if(!item) return
		const name = item?.firstname +" "+ item?.lastname 
		return name
	}

    const reAssignFinder = (item) => {
        let arr = [6,3,7,8,9]
        if(item?.visatype?.sop){
            arr.push(22)
        }
        if(item?.visatype?.doc){
            arr.push(5)
        }
        return arr
    }

    return (
        <div>

            <div className="breadcrumb-and-otherbuttons">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item active" aria-current="page">Clients</li>
                    </ol>
                </nav>
            </div>

            <div className="add_clients" style={{borderBottom:'0px'}}>
                <div className="row">
                    <div className="col-md-6 appointments-hing-wrpr">
                        <i className="appointments-icon">
                            <img src={require('../../../assets/images/appointments-today-icon.png')} width="" height="" alt="" />
                        </i>
                        <h2><span>My</span>Clients</h2>
                    </div>
                    <div className="col-md-12">
                        <hr className="progress-hr" />
                    </div>
                </div>

                <div id="accordion" className="add_clients_accordion_form">

                    <div className="card" id="appointments_wrpr">
                        <div className="card-header flex justify-between">
                            <a className="collapsed card-link" href="" style={{ pointerEvents: 'none' }}>
                                My Clients
                            </a>
                            <div>
                                <button className='text-[12px] flex gap-2 items-center' onClick={resetFilters}>
                                    <IconClearAll size={17} />
                                    Clear Filters
                                </button>
                            </div>
                        </div>
                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">
                                <div className='col-md-12'>
                                
                                    <AdvancedSearch />

                                    <div className="search-results-count-wrpr">
                                        {
                                        result?.data?.totalItems > 0 ?
                                        `Search Results ${result?.data?.currentPage * 25 + 1}-${result?.data?.currentPage * 25 + result?.data?.items?.length} of ${result?.data?.totalItems}` :
                                        `O results found`
                                        }
                                    </div>

                                    <div className="">
                                        {loader ? 
                                        <LoaderCommon />:
                                        result?.data?.totalItems > 0 ?
                                            <div className="table-wrpr">
                                                <div className="table-section">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <table className="table prospect-table mb-0">
                                                                <thead>
                                                                    <tr>
                                                                        <th width="30">#</th>
                                                                        <th>Profile Status</th>
                                                                        <th>Office</th>
                                                                        <th>Date Created</th>
                                                                        <th>Applicant Name</th>
                                                                        <th>Contact Number</th>
                                                                        <th>Visa Type</th>
                                                                        <th>Intake</th>
                                                                        <th>Counselor</th>
                                                                        <th>Reg. Date</th>
                                                                        <th>Prospect Status</th>
                                                                        <th>Reffered By</th>
                                                                        <th width='150'>&nbsp;</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="ui-sortable">
                                                                    {result?.data?.items?.map((item, index) => (
                                                                        <Fragment key={index}>
                                                                            <tr className="ui-sortable-handle">
                                                                                <td>{(index + 1) + (result?.data?.currentPage * 25)}</td>
                                                                                <td>
                                                                                    <LeadStatus item={item} />
                                                                                </td>
                                                                                <td>{item?.office?.name}</td>
                                                                                <td>{moment(item?.createdAt).format('DD-MM-YYYY')}</td>
                                                                                <td>
                                                                                    {
                                                                                        item?.intended_programs?.length > 0 ?
                                                                                        <a style={{ cursor: 'pointer', color: '#1000FF' }} onClick={() => { expantButton(index) }}>
                                                                                            {decode(item?.applicant_name)} {decode(item?.middlename)} {decode(item?.lastname)} <i className='icon-arrow-down-thick'></i>
                                                                                        </a> :
                                                                                        <Fragment>{decode(item?.applicant_name)} {decode(item?.middlename)} {decode(item?.lastname)}</Fragment>
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    <div className="email-mobile flex flex-col">
                                                                                        <div>{item.mobile_no}</div>
                                                                                        <div>{item.landline_no}</div>
                                                                                        <div>{item.whatsapp_no}</div>
                                                                                    </div>
                                                                                </td>
                                                                                <td>{item?.visatype?.country?.icon} {item?.visatype?.description}</td>
                                                                                <td>{item?.intake?.intake}</td>
                                                                                <td>
                                                                                    {counselorName(item?.assignUser?.CounselorAssign?.userdetail)}
                                                                                </td>
                                                                                <td>
                                                                                    {item?.registered_date ? <span className="registered">{moment(item?.registered_date).format('DD-MM-YYYY')}</span> : '-'}
                                                                                </td>
                                                                                <td>
                                                                                    <ProspectStatusList item={item} />
                                                                                </td>
                                                                                <td>{item?.refferal?.refferal}</td>
                                                                                <td>
                                                                                    <div className="grid grid-cols-12 gap-1 w-[160px]">
                                                                                        <div className='col-span-12'>
                                                                                            <FollowUp item={item} />
                                                                                        </div>
                                                                                        <div className='col-span-12'>
                                                                                            {
                                                                                                item?.registered_status === "NO" && 
                                                                                                <Fragment>
                                                                                                    {
                                                                                                        item?.visatype?.program === "YES" ?
                                                                                                        <Fragment>
                                                                                                            {item?.status_of_prospect?.id === 6 ? 
                                                                                                                <Fragment>
                                                                                                                {
                                                                                                                    item?.intended_programs.filter(element=> element.status === "CONFIRMED" && element.app_status === "CONFIRMED")?.length > 0 ?
                                                                                                                    <RegistrationModal item={item} />:
                                                                                                                    <button disabled className="btn-customized opacity-60">Mark Registration</button>
                                                                                                                }
                                                                                                                </Fragment>:
                                                                                                                <button disabled className="btn-customized opacity-60">Mark Registration</button>
                                                                                                            }
                                                                                                        </Fragment>:
                                                                                                        <Fragment>
                                                                                                            {item?.status_of_prospect?.id === 6 ? 
                                                                                                                <Fragment>
                                                                                                                    <RegistrationModal item={item} />
                                                                                                                </Fragment>:
                                                                                                                <button disabled className="btn-customized opacity-60">Mark Registration</button>
                                                                                                            }
                                                                                                        </Fragment>
                                                                                                    }
                                                                                                </Fragment>
                                                                                            }
                                                                                            {item?.registered_status === "YES" &&
                                                                                                <AssignModal
                                                                                                item={item} 
                                                                                                roles={reAssignFinder(item)}
                                                                                                selectedRole={3} 
                                                                                                label="Assign / Reassign" 
                                                                                                noOffice={true} />
                                                                                            }
                                                                                        </div>
                                                                                        <div className='col-span-12'>
                                                                                            <div className='grid grid-cols-10 gap-1'>
                                                                                                {
                                                                                                    item?.registered_status === "NO" &&
                                                                                                    <div className='col-span-4'>
                                                                                                        <AssignModal
                                                                                                        item={item} 
                                                                                                        roles={[3]} 
                                                                                                        selectedRole={3} 
                                                                                                        label="Reassign" 
                                                                                                        noOffice={true} 
                                                                                                        />
                                                                                                    </div>
                                                                                                }
                                                                                                <div className={item?.registered_status === "NO" ? 'col-span-3' : 'col-span-5'}>
                                                                                                    <ApplicantEditButton item={item} />
                                                                                                </div>
                                                                                                <div className={item?.registered_status === "NO" ? 'col-span-3' : 'col-span-5'}>
                                                                                                    <ApplicantViewButton item={item} />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan="13" className={active.index === index && active.status ? "dropdown-table-wrpr active" : "dropdown-table-wrpr"}>
                                                                                    <table className="table dropdown-table">
                                                                                        <thead>
                                                                                            <tr>
                                                                                                <th>Prioriy</th>
                                                                                                <th>College / University</th>
                                                                                                <th>Program Name</th>
                                                                                                <th>Intake</th>
                                                                                                <th>Application Status</th>
                                                                                                <th>Duration</th>
                                                                                                <th>Code</th>
                                                                                                <th>Status</th>
                                                                                                <th></th>
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {item?.intended_programs
                                                                                            ?.sort((a, b) => a.priority - b.priority)
                                                                                            ?.map((items, indexes) => (
                                                                                                <tr key={indexes}>
                                                                                                    <td width="15">
                                                                                                        <div
                                                                                                        className='font-[600] flex items-center justify-center w-[25px] h-[25px] rounded-full text-white'
                                                                                                        style={{background: items?.status === "CONFIRMED" ? 'green' : 'orange'}}>
                                                                                                            {items?.priority}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>{items?.college?.name}</td>
                                                                                                    <td>
                                                                                                        {items?.program?.programlink ?
                                                                                                        <a className='text-blue-700' href={items?.program?.programlink} target='_blank'>
                                                                                                            {items?.program?.name}
                                                                                                        </a>:
                                                                                                        <div>{items?.program?.name}</div>
                                                                                                        }
                                                                                                    </td>
                                                                                                    <td>{items?.intake?.intake}</td>
                                                                                                    <td>
                                                                                                        <ApplicationStatusModal item={items} />
                                                                                                    </td>
                                                                                                    <td>{items?.program?.duration && `${items?.program?.duration} Year`}</td>
                                                                                                    <td>{items?.program?.program_code}</td>
                                                                                                    <td>{items?.status}</td>
                                                                                                    <td className='flex justify-end'>
                                                                                                        <div className='w-[120px]'>
                                                                                                            <ApplicationStatusCreateModal status={appStatusList?.data?.data?.items} item={items} application={item} />
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            ))}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </td>
                                                                            </tr>
                                                                        </Fragment>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                            <PaginationComponent result={result} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>:
                                            <div className='mt-24 mb-24'>
                                                <Empty
                                                description={<div className="text-sm text-blue-700" style={{fontFamily: "var(--primary-font)"}}>Clients not found!</div>}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ApplicantListing