import { Spin,  Popconfirm, Empty } from 'antd'
import { decode } from 'html-entities'
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import authQueries from '../../queries/authQueries';
import { useLoaderStore, usePaginationStore, userStore } from '../../store/userStore';
import { DeleteMessage } from '../Common/AlertMessage';
import { Avatar, Dropdown, Modal } from '@nextui-org/react';
import PaginationComponent from '../Common/PaginationComponent';
import MasterHeader from '../Common/MasterHeader';
import LoaderCommon from '../Common/LoaderCommon';
import { useUserSearch } from '../../store/userSearchStore';
import { useApi } from '../Common/ApiProvider';
import InputSearch from '../Common/InputSearch';
import SelectSearch from '../Common/SelectSearch';

const UserList =()=> {
    
    const user = userStore();
    const currentPage = usePaginationStore((state) => state.currentPage);
    const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);
    const data = useUserSearch((state) => state);
    const loader = useLoaderStore((state) => state.loader);
    const setLoader = useLoaderStore((state) => state.setLoader);

    const { apiData, setApiDataAndNotify } = useApi()
    
    const setName = useUserSearch((state) => state.setName);
    const setRole = useUserSearch((state) => state.setRole);
    const setStatus = useUserSearch((state) => state.setStatus);
    const setVisatype = useUserSearch((state) => state.setVisatype);
    const setOffice = useUserSearch((state) => state.setOffice);

    const name = data?.name
    const role = data?.role
    const status = data?.status
    const visatype = data?.visatype
    const office = data?.office

    const [result, setResult] = useState([]);
    const [dropDown, setDropDown] = useState([])


    const navigate = useNavigate();

    const dropdownList = authQueries.useAdminUserListDrowpdowns(
        (response) => {
            setDropDown(response)
        }   
    ); 

    const userListing = authQueries.useUserRoleMutationList(
        (response) => {
            setLoader(false)
            setResult(response)
        }   
    ); 

    useEffect(()=>{
        userListing.mutateAsync({
            ...data,
            page:currentPage, 
            size:25,
        })
    },[data, currentPage, apiData])

    useEffect(()=>{
        dropdownList.mutateAsync()
    },[])

    const editUser =(id, roles, office, visa)=>{

        let roleArray = []
        roles.map((item) => { 
            roleArray.push(item.rid)
        });

        let officeArray = []
        office.map((item) => {
            officeArray.push(item.oid)
        });

        let visaArray = []
        visa.map((item) => {
            visaArray.push(item.vid)
        });

        user.setRoleId(roleArray)
        user.setUserVisaId(visaArray)
        user.setUserOfficeId(officeArray)

        navigate('/useredit/'+id)
    }

    const addUser =()=>{
        navigate('/useradd')
    }

    const deleteUser =(id)=>{
    deleteApplication.mutateAsync(id)
    }

    const deleteApplication = authQueries.useUserRoleMutationDelete(
        (response) => {
            setApiDataAndNotify(response)
            DeleteMessage({type: 'success', content: 'Successfully Deleted'});
        }
    ) 

    const userList =(rid, id)=> {
        user.setPagination({page:1, size:50})
        user.setUserId({role: rid, uid:id})
        navigate('/leadlist')
    }

    const inputSearchArry = [
        {
            label: "Username",
            placeholder: "Search by Username",
            size: 2,
            state: name,
            setState: setName,
            type: "text",
            input: InputSearch,
        },
        {
            label: "Role",
            placeholder: "Search by Role",
            size: 2,
            state: role,
            setState: setRole,
            type: "text",
            value: dropDown?.role,
            input: SelectSearch,
            name: "label"
        },
        {
            label: "Status",
            placeholder: "Search by Status",
            size: 2,
            state: status,
            setState: setStatus,
            type: "text",
            value: [{id: "ALL", label: "All"}, {id: "ACTIVE", label: "Active"}, {id: "INACTIVE", label: "Inactive"}],
            input: SelectSearch,
            name: "label"
        },
        // {
        //     label: "Visatype",
        //     placeholder: "Search by Visatype",
        //     size: 2,
        //     state: visatype,
        //     setState: setVisatype,
        //     type: "text",
        //     value: dropDown?.visatype,
        //     input: SelectSearch,
        //     name: "name"
        // },
        // {
        //     label: "Office",
        //     placeholder: "Search by Office",
        //     size: 2,
        //     state: office,
        //     setState: setOffice,
        //     type: "text",
        //     value: dropDown?.office,
        //     input: SelectSearch,
        //     name: "name"
        // },
    ]

  return (

    <div>

        <div>
            <div className="breadcrumb-and-otherbuttons mb-2">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                        <li className="breadcrumb-item active" aria-current="page">Users</li>
                    </ol>
                </nav>
                <div className="other-buttons">
                    <button className="bg-[#323a51] text-[12px] text-white px-4 py-[10px] rounded-lg" onClick={addUser}>Create User</button>
                </div>
            </div>
        </div>

        <div className="add_clients">
            <div className="row">
                <div className="col-md-6 appointments-hing-wrpr">
                    <i className="appointments-icon">
                        <img src={require('../../assets/images/appointments-today-icon.png')} width="" height="" alt="" />
                    </i>
                    <h2><span>My</span>Users</h2>	
                </div>
                <div className="col-md-12">
                    <hr className="progress-hr" />
                </div>
            </div>

            <div id="accordion" className="add_clients_accordion_form">
                <div className="card" id="appointments_wrpr">
                    <div className="card-header">
                        <a className="collapsed card-link"  href="" style={{pointerEvents:'none'}}>
                            Users
                        </a>
                    </div>
                    <div id="appointment_any" className="collapse show" data-parent="#accordion">
                        <div className="card-body">
                            <div className="row">
                                

                                <div className="col-md-12">
                                    <div className="grid grid-cols-12 max-[1389px]:grid-cols-10 max-[1163px]:grid-cols-8 max-[929px]:grid-cols-6 max-[585px]:grid-cols-4 max-[445px]:grid-cols-2 gap-x-6 gap-y-5 !mb-5">
                                        {
                                            inputSearchArry?.map((item, index)=> {
                                                return(
                                                <div key={index} className={`col-span-${item?.size} flex flex-col gap-2`}>
                                                    <item.input item={item} />
                                                </div>
                                            )})
                                        }
                                    </div>  

                                    <div className="search-results-count-wrpr">
                                        {
                                        result?.data?.totalItems > 0 ?
                                        `Search Results ${result?.data?.currentPage * 25 + 1}-${result?.data?.currentPage * 25 + result?.data?.items?.length} of ${result?.data?.totalItems}` :
                                        `O results found`
                                        }
                                    </div>

                                    {
                                        loader ?
                                        <LoaderCommon />:
                                        result?.data?.totalItems > 0 ?
                                        <div className="table-wrpr">
                                            <div className="table-section">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <table style={{fontSize:"12px"}} className="table prospect-table">
                                                            <thead>
                                                                <tr>
                                                                    <th width="2%">#</th>
                                                                    <th width="2%"></th>
                                                                    <th width="18%">Name</th>
                                                                    <th width="18%">User Name</th>
                                                                    <th width="15%">Phone</th>
                                                                    <th width="15%">Email</th>
                                                                    <th width="10%">Primary Role</th>
                                                                    <th width="10%">&nbsp;</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="ui-sortable">
                                                                {result?.data?.items.map((item, index) => (
                                                                    <tr className="ui-sortable-handle" key={index}>
                                                                        <th className="">{(index+1) + (result?.data?.currentPage * 25)}</th>
                                                                        <td>
                                                                            <Avatar
                                                                            size="md"
                                                                            src={item?.userdetail?.image ? `${item?.userdetail?.image}` : require('../../assets/images/profile-avatar.jpg')}
                                                                            bordered
                                                                            />
                                                                        </td>
                                                                        <td>
                                                                            {decode(item?.userdetail?.firstname)} {decode(item?.userdetail?.lastname)}
                                                                        </td>
                                                                        <td>
                                                                            {item.username}
                                                                        </td>
                                                                        <td>{item?.userdetail?.phone}</td>
                                                                        <td>{item?.userdetail?.email}</td>
                                                                        <td>{item?.role?.label} {item?.role?.name === "OTHERS" && `(${item?.specific_role})`}</td>
                                                                        <td className="action-icons">
                                                                            <div className="items-end" style={{display:'flex', flexDirection:'column'}}>
                                                                                <div style={{display:'flex', flexDirection:'row', gap:"5px"}}>
                                                                                    <button 
                                                                                    style={{marginLeft:"0px", width:'50px'}} 
                                                                                    onClick={()=>{
                                                                                        editUser(
                                                                                            item.id, 
                                                                                            item?.rolemultiples,
                                                                                            item?.useroffices,
                                                                                            item?.uservisas,
                                                                                        )
                                                                                    }} 
                                                                                    className="btn-visadata"
                                                                                    >
                                                                                        Edit
                                                                                    </button> 

                                                                                    <Popconfirm
                                                                                        placement="left"
                                                                                        title="Are you sure to delete this User ?"
                                                                                        onConfirm={() => {deleteUser(item.id)}}
                                                                                        okText="Yes"
                                                                                        cancelText="No"
                                                                                    >
                                                                                        <button style={{background:"#d24b55", marginLeft:"0px", width:'50px'}}  className="btn-visadata delete">Delete</button>
                                                                                    </Popconfirm>
                                                                                </div>
                                                                                <div>
                                                                                    <button 
                                                                                    onClick={()=>userList(item?.role_id,item?.id)} 
                                                                                    style={{marginLeft:"0px", width:'105px'}} 
                                                                                    className="btn-visadata"
                                                                                    >
                                                                                        Bulk Assign
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                        <PaginationComponent result={result} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>:
                                        <div className='mt-24 mb-24'>
                                            <Empty
                                            description={<div className="text-sm text-blue-700" style={{fontFamily: "var(--primary-font)"}}>Users not found!</div>}
                                            />
                                        </div>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>


  )
}

export default UserList