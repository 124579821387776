function formatIndianPhoneNumber(number) {
    
    const cleanedNumber = number.replace(/[^0-9]/g, '');
    
    if (cleanedNumber.length === 10) {
      return `+91 ${cleanedNumber.slice(0, 3)} ${cleanedNumber.slice(3, 6)} ${cleanedNumber.slice(6, 10)}`;
    } else if (cleanedNumber.length > 10) {
      const slicedNumber = cleanedNumber.slice(-10); // Get last 10 digits
      return `+91 ${slicedNumber.slice(0, 3)} ${slicedNumber.slice(3, 6)} ${slicedNumber.slice(6, 10)}`;
    } else {
      return number;
    }
    
}

export default formatIndianPhoneNumber