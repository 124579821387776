import apiRequest from '../apis/api-request';
import { userStore } from '../store/userStore';

const accessToken = userStore.getState().user.accessToken

const eventService = {
   
    eventList: async (data) => {

        const page = data?.page ? data?.page : 0
        const size = data?.size ? data?.size : 25
        const search = data?.search ? data?.search : ""

        return apiRequest({
            method: "GET",
            url: `/api/event?size=${size}&page=${page}&search=${search}`,
            headers: {
                Authorization: "Bearer " + accessToken
            },
        });

    },

    createEvent: async (data) => {
        return apiRequest({
            method: "POST",
            url: `/api/event`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            },
        });
    },

    updateEvent: async (data) => {
        return apiRequest({
            method: "PUT",
            url: `/api/event/${data.id}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            },
        });
    },

    deleteEvent: async (id) => {
        return apiRequest({
            method: "DELETE",
            url: `/api/event/${id}`,
            headers: {
                Authorization: "Bearer " + accessToken
            },
        });
    },

    viewEvent: async (data) => {
        return apiRequest({
            method: "GET",
            url: `/api/event/${data.id}`,
            headers: {
                Authorization: "Bearer " + accessToken
            },
        });
    },

    upcomingEvent: async () => {
        return apiRequest({
            method: "GET",
            url: `/api/event/upcoming/event`,
            headers: {
                Authorization: "Bearer " + accessToken
            },
        });
    },

    masterDropdown: async (data) => {
        return apiRequest({
            method: "GET",
            url: `/api/event/master/dropdown`,
            headers: {
                Authorization: "Bearer " + accessToken
            },
        });
    },

}

export default eventService