/* eslint-disable jsx-a11y/anchor-is-valid */
import { Empty, message } from 'antd'
// import { decode } from 'html-entities'
import React, { Fragment, useEffect, useState } from 'react'
import authQueries from '../../queries/authQueries';
import { useLoaderStore, usePaginationStore, userStore } from '../../store/userStore';
import $ from "jquery";
import moment from 'moment';
import { Modal, Button, Text, Loading } from "@nextui-org/react";
import LoaderCommon from '../Common/LoaderCommon'
import LeadStatus from '../Common/LeadStatus';
import FollowUp from '../Common/FollowUp';
import ApplicantViewButton from '../Common/ApplicantViewButton';
import PaginationComponent from '../Common/PaginationComponent';
import ProspectStatusList from '../Common/ProspectStatusList';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import AdvancedSearch from '../Common/AdvancedSearch';
import { decode } from 'html-entities';
import { useAdvancedSearch } from '../../store/advancedSearchStore';
import { IconClearAll } from '@tabler/icons-react';
import ApplicantEditButton from '../Common/ApplicantEditButton';
import AssignModal from '../Common/AssignModal';


const CeoApplicantListing = () => {

    const appStore = userStore();

    const currentPage = usePaginationStore((state)=> state.currentPage)
    const setCurrentPage = usePaginationStore((state)=> state.setCurrentPage)

    const [result, setResult] = useState([]);

    const data = useAdvancedSearch((state) => state);


    const visa = useAdvancedSearch((state) => state.visa);
    const loader = useLoaderStore((state) => state.loader);
    const setLoader = useLoaderStore((state) => state.setLoader);
    const resetPage = usePaginationStore((state) => state.resetPage);
    const resetAll = useAdvancedSearch((state)=> state.resetAll)

    const ApplicantListing = authQueries.useCEOClientListMutation(
        (response) => {
            setLoader(false)
            setResult(response)
        }
    );

    useEffect(() => {
        setLoader(true)
    }, [data, currentPage])

    useEffect(() => {
        ApplicantListing.mutateAsync({
            page: currentPage,
            size: 25,
            ...data,
        })
    }, [data, currentPage])


    const exportApps = authQueries.useExportCEOAllApplicantMutation(
        (res)=> {
            if(res?.message === "succes"){
                window.open(`${process.env.REACT_APP_BASE_URL}/api/impex/download/${res?.filename}`, '_blank')
            }
        }
    )

    const handleExport =()=> {
        if(result?.data?.totalItems > 100000){
            return toast.error('Inform users if the download speed may be affected by high server load')
        }

        if(result?.message === "Sorry! No Application found."){
            return toast.error('Refine Your Search: Consider refining your search criteria to find applicants for export. Click here to adjust your search settings.')
        }
        
        exportApps.mutateAsync({
           ...data
        })
    }

    const resetFilters =()=>{
        resetAll()
        resetPage()
    }


    return (
        <div className="add_clients">

            <div className="row">
                <div className="col-md-6 appointments-hing-wrpr">
                    <i className="appointments-icon">
                        <img src={require('../../assets/images/appointments-today-icon.png')} width="" height="" alt="" />
                    </i>
                    <h2><span>My</span>Clients</h2>
                </div>
                <div className="col-md-12">
                    <hr className="progress-hr" />
                </div>
            </div>

            <div id="accordion" className="add_clients_accordion_form">
                <div className="card" id="appointments_wrpr">
                    <div className="card-header flex justify-between">
                        <a className="collapsed card-link" href="" style={{ pointerEvents: 'none' }}>
                            My Clients
                        </a>
                        <div>
                            <button className='text-[12px] flex gap-2 items-center' onClick={resetFilters}>
                                <IconClearAll size={17} />
                                Clear Filters
                            </button>
                        </div>
                    </div>
                    <div id="appointment_any" className="collapse show" data-parent="#accordion">
                        <div className="card-body">

                            <AdvancedSearch />

                            <div className="table-list-search-wrpr simple-search">
                                <div className="search-results-count-wrpr flex justify-between">
                                    {
                                    result?.data?.totalItems > 0 ?
                                    `Search Results ${result?.data?.currentPage * 25 + 1}-${result?.data?.currentPage * 25 + result?.data?.items?.length} of ${result?.data?.totalItems}` :
                                    `O results found`
                                    }
                                    <div className='w-[100px]'>
                                        <button disabled={exportApps.isLoading} className='btn-customized-large flex gap-2' onClick={handleExport}>
                                            {
                                                exportApps.isLoading && <Loading color="white" size="xs" />
                                            }
                                            Export
                                        </button>
                                    </div>
                                </div>

                                {
                                    loader ? 
                                    <LoaderCommon />:
                                    result?.data?.totalItems > 0 ?
                                    <div className="table-wrpr">
                                        <div className="table-section">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <table className="table prospect-table">
                                                        <thead>
                                                            <tr className='alt-colors'>
                                                                <th width="30">#</th>
                                                                <th>Profile Status</th>
                                                                <th>Office</th>
                                                                <th>Date Created</th>
                                                                <th>Applicant Name</th>
                                                                <th>Contact Number</th>
                                                                <th>Visa Type</th>
                                                                <th>Intake</th>
                                                                <th>Counselor</th>
                                                                <th>Reg. Date</th>
                                                                <th>Prospect Status</th>
                                                                <th>Reffered By</th>
                                                                <th width='150'>&nbsp;</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="ui-sortable">
                                                            {result?.data?.items?.map((item, index) => (
                                                                <Fragment key={index}>
                                                                    <tr>
                                                                        <th className="">{(index + 1) + (result.data?.currentPage * 25)}</th>
                                                                        <td>
                                                                            <LeadStatus item={item} />
                                                                        </td>
                                                                        <td>{item?.office?.name}</td>
                                                                        <td>{moment(item?.createdAt).format('DD-MM-YYYY')}</td>
                                                                        <td>
                                                                            {decode(item?.applicant_name)} {decode(item?.middlename)} {decode(item?.lastname)}
                                                                        </td>
                                                                        <td>
                                                                            <div className="email-mobile flex flex-col">
                                                                                <div>{item.mobile_no}</div>
                                                                                <div>{item.landline_no}</div>
                                                                                <div>{item.whatsapp_no}</div>
                                                                            </div>
                                                                        </td>
                                                                        <td>{item?.visatype?.description}</td>
                                                                        <td>{item?.intake?.intake}</td>
                                                                        <td>
                                                                            {item?.assignUser?.CounselorAssign?.userdetail?.firstname} {item?.assignUser?.CounselorAssign?.userdetail?.lastname}
                                                                        </td>
                                                                        <td>
                                                                            {item?.registered_date ? <span className="registered">{moment(item?.registered_date).format('DD-MM-YYYY')}</span> : '-'}
                                                                        </td>
                                                                        <td><ProspectStatusList item={item} /></td>
                                                                        <td>{item?.refferal?.refferal}</td>
                                                                        <td>
                                                                            <div className='grid grid-cols-10 gap-1 min-w-[160px]'>
                                                                                <div className='col-span-10'>
                                                                                    <FollowUp item={item}/>
                                                                                </div>
                                                                                <div className='col-span-4'>
                                                                                <AssignModal
                                                                                item={item}
                                                                                roles={[3,6,7,8,9]}
                                                                                selectedRole={3} 
                                                                                label="Reassign"
                                                                                noOffice={true}
                                                                                />
                                                                                </div>
                                                                                <div className='col-span-3'>
                                                                                    <ApplicantEditButton item={item} />
                                                                                </div>
                                                                                <div className='col-span-3'>
                                                                                    <ApplicantViewButton item={item} />
                                                                                </div>
                                                                            </div>

                                                                        </td>
                                                                    </tr>
                                                                </Fragment>

                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    <PaginationComponent result={result} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                                </div>
                                            </div>
                                        </div>
                                    </div> :
                                    <div className='mt-24 mb-24'>
                                        <Empty
                                        description={<div className="text-sm text-blue-700" style={{fontFamily: "var(--primary-font)"}}>Clients not found!</div>}
                                        />
                                    </div>
                                }

                            </div>

                        </div>
                    </div>
                </div>

            </div>

        </div>
    )

}

export default CeoApplicantListing