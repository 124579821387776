import logoutService from "../services/logoutService";
import { useMutation } from "react-query";

const useLogoutAllUser = (onData) => {
  return useMutation(logoutService.logoutUser, {
    onSuccess: (data) => onData(data),
  })
}

const useVerifyUser = (onData) => {
  return useMutation(logoutService.verifyUser, {
    onSuccess: (data) => onData(data),
  })
}

const logoutQueries = {
    useLogoutAllUser,
    useVerifyUser
}

export default logoutQueries