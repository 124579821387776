/* eslint-disable jsx-a11y/anchor-is-valid */
// import { decode } from 'html-entities'
import React, { Fragment, useEffect, useState } from 'react'
import { Empty } from 'antd';
import { decode } from 'html-entities';
import LoaderCommon from '../Common/LoaderCommon';
import { Link, useNavigate } from 'react-router-dom';
import callCenterQueries from '../../queries/callCenterQueries';
import { useForm } from 'react-hook-form';
import LeadStatus from '../Common/LeadStatus';
import moment from 'moment';
import ProspectStatusList from '../Common/ProspectStatusList';
import CallCenterCounselor from './CallCenterCounselor';
import formatIndianPhoneNumber from '../../utils/formatMobileNumber';

const CallCenterApplicant = () => {

    const [result, setResult] = useState([])
    const [showCounselor, setShowCounselor] = useState(false)


    const { register, handleSubmit, setValue } = useForm({})

    const getApplicant = callCenterQueries.useCallCenterApplicationSearch(
        (res)=>{
            if(res?.routing){
                handleShowCounselor()
            }else{
                handleHiddenCounselor()
            }
            if(res?.message === "success"){
                setResult(res?.data)
            }
        }
    )

    const onSearch =(data)=>{
        getApplicant.mutateAsync(data)
    }

    const counselorName = (element)=>{
        const detail = element?.CounselorAssign?.userdetail
        if(!detail) return 'N/A'
        return detail?.firstname + ' ' + detail?.lastname
    }

    const counselorPhone = (element)=>{
        const detail = element?.CounselorAssign?.userdetail
        if(!detail) return 'N/A'

        const formattedNumber = formatIndianPhoneNumber(detail?.phone);

        return formattedNumber
    }

    const handleShowCounselor =()=>{
        setShowCounselor(true)
    }

    const handleHiddenCounselor =()=>{
        setShowCounselor(false)
    }

    return (
        <div>
            <div className="breadcrumb-and-otherbuttons">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Call Center</li>
                    </ol>
                </nav>
            </div>
            <div className="add_clients" style={{borderBottom:'0px'}}>
                <div className="flex items-center gap-[4px] mb-4">
                    <i className="followup-icon">
                        <img src={require('../../assets/images/appointments-today-icon.png')} width="" height="" alt="" />
                    </i>
                    <div style={{display:'flex', flexDirection:'column'}}>
                        <div style={{display:'flex', alignItems:'center', gap:'10px'}}>
                            <span className="text-[16px] font-medium">Call Center - Applicant</span>	
                        </div>
                    </div>
                </div>
                <div id="accordion" className="add_clients_accordion_form">
                    <div className="card" id="appointments_wrpr">
                        <div className="card-header flex justify-between">
                            <a className="collapsed card-link" href="" style={{ pointerEvents: 'none' }}>
                                Call Center
                            </a>
                        </div>
                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">
                                <div className='col-md-12'>
                                
                                    <form onSubmit={handleSubmit(onSearch)}>
                                        <div className='grid grid-cols-12 gap-4'>
                                            <div className="col-span-2 flex flex-col">
                                                <div className='text-[12px] h-[25px]'>Contact No</div>
                                                <input
                                                {...register("mobile")}
                                                placeholder='Enter Contact No' 
                                                name="mobile"
                                                type="text"
                                                className="bg-white border border-solid border-gray-300 rounded-md w-full !min-h-[40px] pl-[10px] pr-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0"
                                                />
                                            </div>
                                            <div className="col-span-2 flex flex-col">
                                                <div className='text-[12px] h-[25px]'>Applicant Name</div>
                                                <input
                                                {...register("name")}
                                                placeholder='Enter Applicant Name' 
                                                name="name"
                                                type="text"
                                                className="bg-white border border-solid border-gray-300 rounded-md w-full !min-h-[40px] pl-[10px] pr-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0"
                                                />
                                            </div>
                                            <div className="col-span-2 flex flex-col">
                                                <div className='text-[12px] h-[25px]'>Email</div>
                                                <input
                                                {...register("email")}
                                                placeholder='Enter Email' 
                                                name="email"
                                                type="text"
                                                className="bg-white border border-solid border-gray-300 rounded-md w-full !min-h-[40px] pl-[10px] pr-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0"
                                                />
                                            </div>
                                            <div className='col-span-2 flex flex-col'>
                                                <div className='h-[25px]'></div>
                                                <button type="submit" className="btn-customized-large w-[120px] h-[38px] rounded-md">
                                                    Search
                                                </button>
                                            </div>
                                        </div>
                                    </form>

                                    <div className="search-results-count-wrpr mt-3">
                                        {
                                            result?.length > 0 ?
                                            `Search Results - ${result?.length}` :
                                            `O results found`
                                        }
                                    </div>

                                    <div>
                                        {getApplicant.isLoading ? 
                                        <LoaderCommon />:
                                        result?.length > 0 ?
                                            <div className="table-wrpr">
                                                <div className="table-section">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <table className="table prospect-table mb-0">
                                                                <thead>
                                                                    <tr className='text-[13px]'>
                                                                        <th width="30">#</th>
                                                                        <th>Profile Status</th>
                                                                        <th>Office</th>
                                                                        <th>Date Created</th>
                                                                        <th>Applicant Name</th>
                                                                        <th>Contact Number</th>
                                                                        <th>Visa Type</th>
                                                                        <th>Intake</th>
                                                                        <th>Counselor</th>
                                                                        <th>Reg. Date</th>
                                                                        <th>Prospect Status</th>
                                                                        <th>Reffered By</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="ui-sortable">
                                                                    {result?.map((item, index) => (
                                                                        <Fragment key={index}>
                                                                            <tr className="ui-sortable-handle text-[11px]">
                                                                                <td>{index + 1}</td>
                                                                                <td><LeadStatus item={item} /></td>
                                                                                <td>{item?.office?.name}</td>
                                                                                <td>{moment(item?.createdAt).format('DD-MM-YYYY')}</td>
                                                                                <td>
                                                                                    {decode(item?.applicant_name)} {decode(item?.middlename)} {decode(item?.lastname)}
                                                                                </td>
                                                                                <td>
                                                                                    <div className="email-mobile flex flex-col">
                                                                                        <div>{item?.mobile_no}</div>
                                                                                        <div>{item?.landline_no}</div>
                                                                                        <div>{item?.whatsapp_no}</div>
                                                                                    </div>
                                                                                </td>
                                                                                <td>{item?.visatype?.description}</td>
                                                                                <td>{item?.intake?.intake}</td>
                                                                                <td className='font-bold text-[14px]'>
                                                                                    <div className="email-mobile flex flex-col">
                                                                                        <div>{counselorName(item?.assignUser)}</div>
                                                                                        <div className='text-blue-800'>{counselorPhone(item?.assignUser)}</div>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    {
                                                                                        item.registered_date ?
                                                                                        <span className='registered'>
                                                                                            {item.registered_date && moment(item?.registered_date).format('DD-MM-YYYY')}
                                                                                        </span>:
                                                                                        "-"
                                                                                    }
                                                                                </td>
                                                                                <td>
                                                                                    <ProspectStatusList item={item} />
                                                                                </td>
                                                                                <td>{item?.refferal?.refferal}</td>
                                                                            </tr>
                                                                        </Fragment>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>:
                                            <div className='mt-24 mb-24 flex items-center flex-col'>
                                                <Empty
                                                description={<div className="text-sm text-blue-700" style={{fontFamily: "var(--primary-font)"}}>Clients not found!</div>}
                                                />
                                            </div>
                                        }
                                    </div>

                                    {
                                        showCounselor &&
                                        <CallCenterCounselor />
                                    }

                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default CallCenterApplicant