import React, { Fragment, useState } from 'react'
import { Controller } from 'react-hook-form'

const InputColorPicker = ({item, errors, control}) => { 
  return (
    <Fragment>
        <div className="text-[12px] h-4 font-[500]">{item?.label} {item?.required &&<span className="text-[red]">*</span>}</div>
        <Controller
          name={item.name}
          control={control}
          render={({ field }) => (
            <div className="w-10 h-10 border border-gray-300 rounded-[8px]" style={{backgroundColor: field.value || "white"}}>
                <input
                className='w-full h-full opacity-0'
                type='color'
                placeholder={item.placeholder ? item?.placeholder : item?.label}
                name={item?.name}
                {...field}
                />
            </div>
          )}
      />
      <div className='text-[12px] text-red-600'>{errors?.[item?.name]?.message}</div>
    </Fragment>
  )
}

export default InputColorPicker