import React, { Fragment } from 'react'

const InputSearch = ({item}) => {
  return (
    <Fragment>
        <div className="text-[12px] h-4 font-[500]">{item?.label}</div>
        <input
        className="bg-white border border-solid border-gray-300 rounded-md w-full h-[44px] pl-[10px] pr-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0" 
        type={item?.type}
        placeholder={item?.placeholder ? item?.placeholder : item?.label}
        value={item?.state}
        onChange={(e)=> {item?.setState(e.target.value)}}
        />
    </Fragment>
  )
}

export default InputSearch