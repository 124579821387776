import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import InputSearch from "../../../Common/InputSearch";
import SelectSearch from "../../../Common/SelectSearch";
import LoaderCommon from "../../../Common/LoaderCommon";
import { GanttChartSquare } from "lucide-react";
import reportQueries from "../../../../queries/reportQueries";
import toast from "react-hot-toast";
import DistributionApplicantDetails from "./DistributionApplicantDetails";


const DistributionReport =()=>{

    const [result, setResult] = useState([]);
    const [counselorResult, setCounselorResult] = useState([])
    const [selectedOffice, setSelectedOffice] = useState(null)

    const today = moment().format('YYYY-MM-DD')

    const [visatype, setVisatype] = useState(null)
    const [dateRange, setDateRange] = useState("CURRENT")
    const [fromDate, setFromDate] = useState(today)
    const [toDate, setToDate]= useState(today)

    
    const reportBranch = reportQueries.useDistributionReport(
        (response) => {
            if(response?.success){
                setResult(response?.data);   
            }
        }
    );
    
    const dropdown = reportQueries.useDistributionReportDropdown(
        (response) => {
        }
    )

    const reportCounselor = reportQueries.useDistributionReportCounselorWise(
        (response) => {
            setCounselorResult(response)
            if(!response?.success){
                toast.error('No user found')
            }
        }
    );

    useEffect(()=>{
        reportBranch.mutateAsync({
            visa: visatype,
            range: dateRange,
            from: fromDate,
            to: toDate
        })
        dropdown.mutateAsync()
    }, [])

    const handleClick =(item)=>{
        const id = item?.id
        if(id === selectedOffice){
            setSelectedOffice(null)
            return
        }
        setSelectedOffice(id)
        reportCounselor.mutateAsync({
            office_id: id,
            visa: visatype,
            range: dateRange,
            from: fromDate,
            to: toDate
        })
    }

    const searchFields = [
        {
            label: "Date",
            size: 2,
            state: dateRange,
            setState: setDateRange,
            input: SelectSearch,
            value: [{name: "Current Financial Year", id: "CURRENT"}, {name: "Custom Date", id: "CUSTOM"}],
            name: 'name'
        },
        {
            label: "From Date",
            size: 2,
            state: fromDate,
            setState: setFromDate,
            input: InputSearch,
            type: "date",
            hidden: dateRange !== "CUSTOM",
            name: 'name'
        },
        {
            label: "To Date",
            size: 2,
            state: toDate,
            setState: setToDate,
            input: InputSearch,
            type: "date",
            hidden: dateRange !== "CUSTOM",
            name: 'name'
        },
        {
            label: "Visa Type",
            size: 2,            
            state: visatype,
            setState: setVisatype,
            input: SelectSearch,
            value: dropdown?.data?.visatype,
            name: 'name'
        }
    ]

    const onSearch =()=>{
        setSelectedOffice(null)
        reportBranch.mutateAsync({
            visa: visatype,
            range: dateRange,
            from: fromDate,
            to: toDate
        })
    }

    return(
        <div>
            <div className="breadcrumb-and-otherbuttons">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li className="breadcrumb-item active" aria-current="page">Distribution Report</li>
                    </ol>
                </nav>
            </div>
            
            <div className="add_clients">
                <div className="flex items-center gap-[4px] mb-4">
                    <i className="followup-icon">
                        <GanttChartSquare size="25" className='text-[#7D6FAB]' />
                    </i>
                    <div style={{display:'flex', flexDirection:'column'}}>
                        <div style={{display:'flex', alignItems:'center', gap:'10px'}}>
                            <span className="text-[16px] font-medium">Distribution Report</span>	
                        </div>
                    </div>
                </div>

                <div id="accordion" className="add_clients_accordion_form mt-[10px]">
                    <div  className="card" id="appointments_wrpr_employee">
                        <div className="card-header">
                            Distribution Report
                        </div>
                        <div id="appointment_any" className="collapse show" data-parent="#accordion">
                            <div className="card-body">

                            <div className="grid grid-cols-12 max-[1389px]:grid-cols-10 max-[1163px]:grid-cols-8 max-[929px]:grid-cols-6 max-[585px]:grid-cols-4 max-[445px]:grid-cols-2 gap-x-6 gap-y-5 !mb-5"> 
                                {
                                    searchFields?.map((item, index)=>{
                                        if(item?.hidden) return
                                        return(
                                            <div key={index} className="col-span-2 flex flex-col gap-2">
                                                <item.input item={item} />
                                            </div>
                                        )
                                    })
                                }
                                <div className="col-span-1 flex flex-col gap-2">
                                    <div className="text-[12px] h-4 font-[500]" />
                                    <button onClick={onSearch} className='rounded-md text-[12px] !w-full !min-h-[42px] custom-color border-none text-white'>
                                        Search
                                    </button>
                                </div>
                            </div>

                                {reportBranch.isLoading ?
                                    <LoaderCommon />:
                                    <div className="overflow-x-auto max-h-[600px]">                                   
                                        <table border={1} className="table report associate-report collageprogram overflow-auto">
                                            <thead className="sticky top-0 distribution">
                                                <tr className='text-center'>
                                                    <th width="40">#</th>
                                                    <th className='text-left'>Branch</th>
                                                    <th>Hot Clients</th>
                                                    <th>Warm Client</th>
                                                    <th>Cold Client</th>
                                                    <th>Not Interested/Qualified</th>
                                                    <th>Registered</th>
                                                    <th>Fee paid</th>
                                                    <th>Visa Approved</th>
                                                    <th>Total</th>
                                                </tr>
                                                <tr className='text-center'>
                                                    <th width="40">#</th>
                                                    <th className='text-left'>Total</th>
                                                    <th>{findTotal(result, 'hot')}</th>
                                                    <th>{findTotal(result, 'warm')}</th>
                                                    <th>{findTotal(result, 'cold')}</th>
                                                    <th>{findTotal(result, 'notInterestedOrQualified')}</th>
                                                    <th>{findTotal(result, 'registered')}</th>
                                                    <th>{findTotal(result, 'feePaid')}</th>
                                                    <th>{findTotal(result, 'visaApproved')}</th>
                                                    <th>{findTotal(result, 'total')}</th>
                                                </tr>
                                            </thead>
                                            <tbody  className="ui-sortable">
                                                {   
                                                    result?.map((item, index) =>(
                                                        <Fragment>
                                                            <tr key={index} className='text-center distribution'>
                                                                <td>{index + 1}</td>
                                                                <td className='text-left'>
                                                                    <button onClick={()=> handleClick(item)}>
                                                                    {item?.office}
                                                                    </button>
                                                                </td>
                                                                <td>{item?.hot}</td>
                                                                <td>{item?.warm}</td>
                                                                <td>{item?.cold}</td>
                                                                <td>{item?.notInterestedOrQualified}</td>
                                                                <td>{item?.registered}</td>
                                                                <td>
                                                                <DistributionApplicantDetails
                                                                item={item.feePaid}
                                                                counselor={null}
                                                                dateRange={dateRange}
                                                                fromDate={fromDate}
                                                                toDate={toDate}
                                                                type="feePaid"
                                                                header="Fee Paid Clients"
                                                                status={[15,16]}
                                                                office={item?.id}
                                                                />
                                                                </td>
                                                                <td>
                                                                <DistributionApplicantDetails
                                                                item={item.visaApproved}
                                                                counselor={null}
                                                                dateRange={dateRange}
                                                                fromDate={fromDate}
                                                                toDate={toDate}
                                                                type="visaApproved"
                                                                header="Visa Approved Clients"
                                                                status={[17]}
                                                                office={item?.id}
                                                                />
                                                                </td>
                                                                <td>{item?.total}</td>
                                                            </tr>
                                                            {selectedOffice === item?.id &&
                                                                <Fragment>
                                                                    {
                                                                        reportCounselor.isLoading ?
                                                                        <tr>
                                                                            <td colSpan={10}>
                                                                                <LoaderCommon />
                                                                            </td>
                                                                        </tr>:
                                                                        counselorResult?.data?.map((row, rowIndex)=>(
                                                                        <tr key={rowIndex} className='text-center distribution bg-red-400 text-white'>
                                                                            <td>{rowIndex + 1}</td>
                                                                            <td className='text-left'>{row?.name}</td>
                                                                            <td>{row?.hot}</td>
                                                                            <td>{row?.warm}</td>
                                                                            <td>{row?.cold}</td>
                                                                            <td>{row?.notInterestedOrQualified}</td>
                                                                            <td>{row?.registered}</td>
                                                                            <td className="text-blue-700 underline">
                                                                                <DistributionApplicantDetails
                                                                                item={row.feePaid}
                                                                                counselor={row?.id}
                                                                                dateRange={dateRange}
                                                                                fromDate={fromDate}
                                                                                toDate={toDate}
                                                                                type="feePaid"
                                                                                header="Fee Paid Clients"
                                                                                status={[15,16]}
                                                                                office={selectedOffice}
                                                                                />
                                                                            </td>
                                                                            <td className="text-blue-700 underline">
                                                                                <DistributionApplicantDetails
                                                                                item={row.visaApproved}
                                                                                counselor={row?.id}
                                                                                dateRange={dateRange}
                                                                                fromDate={fromDate}
                                                                                toDate={toDate}
                                                                                type="visaApproved"
                                                                                header="Visa Approved Clients"
                                                                                status={[17]}
                                                                                office={selectedOffice}
                                                                                />
                                                                            </td>
                                                                            <td>{row?.total}</td>
                                                                        </tr>
                                                                        ))
                                                                    }
                                                                </Fragment>
                                                            }
                                                        </Fragment>
                                                    ))
                                                }
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                }
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default DistributionReport;

const findTotal = (result, type) =>{
    let total = 0
    result?.map((item)=>(
        total += item[type]
    ))
    return total
}