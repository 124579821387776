import React, { useEffect } from 'react'
import { userStore } from '../../store/userStore'
import toast from 'react-hot-toast'
import { message } from 'antd'
import { useNavigate } from 'react-router-dom'
import logoutQueries from '../../queries/logoutQueries'

const LogoutAllUser = () => {

  const store = userStore()

  const navigate = useNavigate()

  useEffect(() => {
    console.log(store.user?.role, store?.user?.role !== "ADMIN" && store?.user?.role !== "CEO")
    if(store?.user?.role !== "ADMIN" && store?.user?.role !== "CEO") {
      message.error("Access Denied")
      navigate("/")
      return;
    }
  }, []);
  
  const handleLogout = () => {
    const key = window.prompt("Please enter your secret key to logout all users")
    if(!key){
      toast.error("Please enter your secret key")
      return
    }
    logout.mutateAsync(key)
  }

  const logout = logoutQueries.useLogoutAllUser(
    (res) => {
      if(res === "Invalid Key"){
        toast.error("Invalid Key")
      }
    }
  )

  return (
    <div className='min-h-[60vh] flex justify-center items-center'>
      <button onClick={handleLogout} className='btn btn-primary'>Logout All Users</button>
    </div>
  )
}

export default LogoutAllUser
