import { Modal } from '@nextui-org/react'
import { Avatar } from 'antd'
import React, { Fragment, useState } from 'react'
import { userStore } from '../../store/userStore'
import authQueries from '../../queries/authQueries'
import toast from 'react-hot-toast'

const UpdateProfileImage = () => {

    const store = userStore((state)=> state)
    const user = userStore((state)=> state.user)

    const [open, setOpen] = useState(false)
    const [image, setImage] = useState(user?.image ? user?.image : null)

    const handleOpen =()=> {
        setOpen(true)
    }

    const handleClose =()=> {
        setOpen(false)
    }

    const handleImage =(e)=>{

        const file = e.target.files[0]
        if(file){
            const reader = new FileReader()
            reader.onload = () => {
                if(reader.readyState === 2){
                    setImage(reader.result)
                }
            }
            reader.readAsDataURL(file)
        }

        const data = new FormData();
        data.append('file', file)
        imageUploadCreate.mutateAsync(data)
    }

    const imageUploadCreate = authQueries.useFileUploadCommon(
        (response) => {
            if(response?.message === "success"){
                const data = {
                    ...user,
                    image: response?.file,
                }
                store.setUser(data)
                updateProfileImage.mutateAsync({image: response?.file, id: user?.id})
                handleClose()
                return
            }
            toast.error(response?.message)
        }   
    );

    const updateProfileImage = authQueries.useImageUploadMutation(
        (response) => {
            if(response?.message === "success"){
                toast.success('Profile Picture Updated')
            }
        }
    )

  return (
    <Fragment>
        <div className="relative">
            <input onChange={handleImage} className='absolute top-0 left-0 w-full h-full opacity-0' type='file' />
            <button className="dropdown-item">Update Profile Image</button>
        </div>
    </Fragment>
  )
}

export default UpdateProfileImage