import exportService from "../services/exportService";
import { useMutation } from "react-query";

const useExportNotInterestedOrQualified = (oData) => {
  return useMutation(exportService.exportNotInterestedOrQualified, {
    onSuccess: (data) => oData(data),
  })
}

const exportQueries = {
  useExportNotInterestedOrQualified
}

export default exportQueries