import apiRequest from '../apis/api-request';
import { userStore } from '../store/userStore';

const accessToken = userStore.getState().user.accessToken

const logoutService = {
   
    logoutUser: async (key) => {
        return apiRequest({
            method: "GET",
            url: `/api/auth/logout?key=${key}`,
            headers: {
                Authorization: "Bearer " + accessToken
            },
        });
    },

    verifyUser: async () => {
        return apiRequest({
            method: "GET",
            url: `/api/auth/verify`,
            headers: {
                Authorization: "Bearer " + accessToken
            },
        });
    },

}

export default logoutService