import React, { Fragment, useEffect, useState } from 'react'
import authQueries from '../../../queries/authQueries';
import { userStore } from '../../../store/userStore';
import {Popconfirm, message} from 'antd'
import moment from 'moment/moment';
import { decode } from 'html-entities';
import toast from 'react-hot-toast';
import { Checkbox, Tooltip } from '@nextui-org/react';

const FileSelect =({item})=>{

    const appStore = userStore();
    const [result, setResult] = useState({})

    useEffect(()=>{
        const docs = appStore?.appAllData?.fileuploads
        const docItem = docs?.find(element => element.document_id === item.id)
        if(docItem){
            setResult(docItem)
        }else{
            setResult(null)
        }
    },[])

    const viewAllApplicationData = authQueries.useApplicationMutationViewAll(
        async (response) => {
            if (response.data) {
                await appStore.setApplicationAllData(response.data)
                const docs = response?.data?.fileuploads
                const docItem = docs?.find(element => element.document_id === item.id)
                if(docItem){
                    setResult(docItem)
                }else{
                    setResult(null)
                }
            }
        }
    )
    
    const handleChange = async (e, id) => {
        const data = new FormData();
        data.append('file', e)
        data.append('name', `${decode(appStore?.appAllData?.applicant?.applicant_name)}`)
        await uploadFile.mutateAsync(data, document)
    }

    const uploadFile = authQueries.usePdfUploadCommon(
        (response) => {
            if(response?.message === "success"){
                toast.success("File upload successfully")
                if(result){
                    let dataValues = {
                        id: result.id,
                        applicant_id: appStore.appid,
                        document_id: item.id,
                        file: response?.file,
                        status:"ACTIVE"
                    }
                    editDocument.mutateAsync(dataValues)
                }else{
                    let dataValues = {
                        applicant_id: appStore.appid,
                        document_id: item.id,
                        file: response?.file,
                        status:"ACTIVE"
                    }
                    addDocument.mutateAsync(dataValues)
                }            
            }
            if(response?.response?.data?.message === "File type not supported. Only pdf, jpeg, jpg, png and gif files are supported.")
                message.error(response?.response?.data.message)
            }   
    );

    const addDocument = authQueries.useApplicantFileAdd(
        (response) => {
            viewAllApplicationData.mutateAsync(appStore.appid)
        }
    );

    const editDocument = authQueries.useApplicantFileEdit(
        (response) => {
            viewAllApplicationData.mutateAsync(appStore.appid)
        }
    );

    const deleteDocument = authQueries.useApplicantFileDelete(
        (response) => {
            toast.success("successfully deleted")
            viewAllApplicationData.mutateAsync(appStore.appid)
        }
    );

    const deletePdf = authQueries.usePdfDeleteCommon(
        (response) => {
            deleteDocument.mutateAsync(result.id)
        }
    );

    const downloadButton =()=>{
        if(result){
            const file = decode(result.file)
            window.open(file, "_blank")
        }else{
            message.error("File not uploaded.")
        }
    }

    const deleteButton =()=>{
        if(result){
            deletePdf.mutateAsync(result.file)
        }else{
            toast.error("File not uploaded.")
        }
    }


    return(
        <Fragment>
            <td style={{marginRight: '20px'}}>
                <label style={{color: '#5DC97A', backgroundColor: 'rgb(222,239,239)', fontWeight: 'bold', padding: '5px', paddingLeft: '10px', paddingRight: '10px', borderRadius: '5px'}}>{decode(item.document_name)}</label>
            </td>
            <td>
                <input type="file" id="actual-btn2" className='sendBtn' onChange={(e)=>{ handleChange(e.target.files[0], item.id)}} />
                <div className='hoverStyle flex items-center gap-4' style={{cursor: 'pointer'}}>
                    {
                        result ?
                        <img style={{cursor: 'pointer'}} src={require('../../../assets/images/pdficon.png')} className='ml-1' width="20px" height="20px" />:
                        <img style={{cursor: 'pointer'}} src={require('../../../assets/images/fileupload2.png')} width="30px" height={'30px'} />
                    }
                    {result? result?.file?.substring(result?.file?.lastIndexOf('/') + 1): "Upload File"}
                </div>
            </td>
            <td>
                {result? moment(result.updatedAt).format('DD-MM-YYYY, hh:mm:a'): "No data"}
            </td>
            <td className="flex justify-end">
                <div className='flex gap-2'>
                    {
                        result &&
                        <Fragment>
                        <Popconfirm
                            placement="left"
                            title="Are you sure to delete this document ?"
                            onConfirm={deleteButton}
                            okText="Yes"
                            cancelText="No"
                        >
                            <button type='button' className="bg-red-600 h-[30px] w-[60px] rounded-md text-white">Delete</button>
                        </Popconfirm>
                        <button className='btn-customized-large h-[30px] w-[60px]' onClick={downloadButton}>View</button>
                        </Fragment>
                    }
                </div>
            </td>                
        </Fragment>
    )

}

const DocumentDetails = () => {

    const [result, setResult] = useState([]);
    const appStore = userStore();
    const [document, setDocument] = useState(null)
    const [completed, setCompleted] = useState(appStore?.appAllData?.applicant?.document_status === "COMPLETED" ? true : false)
    
    const listDocumentation = authQueries.useDocumentList(
        (response) => {
            setResult(response?.data)
        }   
    );

    useEffect(()=>{
        listDocumentation.mutateAsync(appStore?.appAllData?.applicant?.visa_type_id);
    },[appStore?.appAllData?.applicant?.visa_type_id])
    

    const oldDocuments = authQueries.useOldDocumentListing(
        (res) => {
        }   
    );

    useEffect(()=>{
        if(appStore?.appAllData?.applicant?.assessment_old){
            oldDocuments.mutateAsync(appStore?.appAllData?.applicant?.assessment_old)
        }
    },[])

    const mergPdfDownload = authQueries.usePdfMergeCommon(
        (response) => {
            if(response?.message === "File merged successfully."){
                window.open(process.env.REACT_APP_BASE_URL+"/api/fileuploadcommon/downloadmerge/pdf/"+response?.data)
            }
        }
    )

    const mergPdfView = authQueries.usePdfMergeCommon(
        async (res) => {
            if(res?.message === "File merged successfully."){
                const response = await fetch(process.env.REACT_APP_BASE_URL+"/api/fileuploadcommon/pdf/"+res?.data);
                const blob = await response.blob();
                const blobUrl = URL.createObjectURL(blob);
                window.open(blobUrl, '_blank');
            }
        }
    )

    const exportButton =(type)=> {  
        let documnetData = []
        if(document?.length < 2){
            message.error("Minimum 2 documents required to export.")
            return false
        }
        appStore?.appAllData?.fileuploads?.map((item) => (documnetData.push("documents/"+item.file) ))
        const data = {
            files: documnetData,
            filename: `${appStore?.appAllData?.applicant?.applicant_name}${appStore?.appAllData?.applicant?.middlename && `_${appStore?.appAllData?.applicant?.middlename}`}${appStore?.appAllData?.applicant?.lastname && `_${appStore?.appAllData?.applicant?.lastname}`}_${appStore?.appAllData?.applicant?.mobile_no}${Date.now()}.pdf`
        }
        if(type === "VIEW"){
            mergPdfView.mutateAsync(data)
        }else{
            mergPdfDownload.mutateAsync(data)
        }
    }

    const downloadOldFile =(url)=>{
        if(url){
            window.open(`${url}`, "_blank")
        }else{
            message.error("File not uploaded.")
        }
    }

    const handleDocumentStatus =(status)=>{
        setCompleted(status)
        const data = {
            status: status ? "COMPLETED" : "PENDING",
            id: appStore.appid,
            type: "document"
        }
        changeStatus.mutateAsync(data)
    }

    const changeStatus = authQueries.useChangeDocumentOrIeltsStatus(
        (response) => {
            viewAllApplicationData.mutateAsync(appStore.appid)
        }
    )

    const viewAllApplicationData = authQueries.useApplicationMutationViewAll(
        async (response) => {
            if (response.data) {
                await appStore.setApplicationAllData(response.data)
            }
        }
    )

  return (

        <div className="card">
            <div className="card-header">
                <a className={(appStore.user.role === "DOCUMENTSTAFF" || appStore.user.role === "SOPSTAFF" ) ? "card-link" : "collapsed card-link"} data-toggle="collapse" href="#attachment_ifany">
                    Document Details
                    {(appStore?.user?.role === 'DOCUMENTSTAFF' || appStore.user.role === "SOPSTAFF") &&
                        <span className="applicant-details-wrpr pl-4">
                            <div className='flex gap-2'>
                                <div className='flex gap-2'>
                                    <i className="icon-user text-[14px]"></i>
                                    <div>
                                        {appStore?.appAllData?.applicant?.applicant_name}&nbsp;
                                        {appStore?.appAllData?.applicant?.middlename}&nbsp;
                                        {appStore?.appAllData?.applicant?.lastname }&nbsp;
                                    </div>
                                </div>
                                <div className='flex gap-2'>
                                    <i className="icon-envelope3 text-[14px]"></i>
                                    <div>
                                        {appStore?.appAllData?.applicant?.email}
                                    </div>
                                </div>
                                <div className='flex gap-2'>
                                    <i className="icon-whatsapp mt-[2px]"></i>
                                    <div>
                                        {appStore?.appAllData?.applicant?.whatsapp_no}
                                    </div>
                                </div>
                            </div>
                        </span>
                    }
                </a>

                <div className={appStore?.appAllData?.fileuploads?.length > 0 ? "tab-info fields-completed" :  "tab-info fields-notcompleted" }>
                    <span className={appStore?.appAllData?.fileuploads?.length > 0 ? 'icon-checkmark1' : 'icon-cancel1'}></span>
                </div>


            </div>


            <div id="attachment_ifany" className={(appStore.user.role === "DOCUMENTSTAFF" || appStore.user.role === "SOPSTAFF") ? "collapse show" : "collapse"} data-parent="#accordion">
                <div className="card-body">
                    <div className='my-3 flex justify-end'>
                        
                        <Popconfirm
                            placement="topRight"
                            title={!completed ? "Are you sure you want to mark as completed?" : "Are you sure you want to mark as incomplete?"}
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => handleDocumentStatus(!completed)}
                        >
                            <Checkbox isSelected={completed}>
                                <span className='text-[13px] font-normal mt-1'>Documents Completed</span>
                            </Checkbox>
                        </Popconfirm>

                    </div>
                    <div className="table-wrpr mb-[15px]">
                        <div className="table-section">
                            <div className="row">
                                <div className="col-md-12">
                                    <table className="table prospect-table">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>File Name</th>
                                                <th>Uploaded Date</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody id="sortable" className="ui-sortable">
                                            {result?.map((item, index) => (
                                                <Fragment key={index}>
                                                    <tr className="ui-sortable-handle">
                                                        <FileSelect item={item} /> 
                                                    </tr>
                                                </Fragment>   
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="action-icons col-md-12 flex justify-center gap-3">
                        <button onClick={()=>exportButton("VIEW")} 
                        className="btn-customized-large w-[120px] h-[35px]"
                        >View All</button>
                        <button onClick={()=>exportButton("DOWNLOAD")} 
                        className="btn-customized-large w-[120px] h-[35px]"
                        >Download All</button>
                    </div>
                </div>
                
                {
                    (appStore?.appAllData?.applicant?.assessment_old && (oldDocuments?.data?.data?.length > 0)) &&
                    <div className="mx-4 my-4">
                        <div className="grid grid-cols-12 gap-6">
                            {oldDocuments?.data?.data?.map((item,index)=>(
                                <div className="col-span-2 max-[1346px]:col-span-4 max-lg:col-span-6 max-[727px]:col-span-12  bg-white shadow-lg rounded-xl" key={index}>
                                    <div className='p-3 grid grid-cols-12 items-center'>
                                        <div className='col-span-9'>
                                        <div className='text-[12px] text-[black] font-medium'>{decode(item?.name)}</div>
                                        </div>
                                        <div className='col-span-3 flex justify-end gap-3'>
                                            <button
                                                onClick={()=>downloadOldFile(item?.url)}
                                                className='flex items-center px-2 py-1 border-[1px] rounded-md'
                                                style={{background:'none'}}
                                            >
                                                <i className='icon-eye4 text-[12px] text-black'></i>
                                            </button> 
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                }

            </div>            
        </div>
  )
}

export default DocumentDetails