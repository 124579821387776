import { Modal } from '@nextui-org/react'
import { IconBuilding, IconCalendar, IconClock, IconLocation, IconMapPin, IconUsersGroup, IconWorld } from '@tabler/icons-react'
import React, { Fragment, useEffect, useState } from 'react'
import { useDelegateStore } from '../../store/delegateStore'
import eventQueries from '../../queries/eventQueries'
import moment from 'moment'
import LoaderCommon from './LoaderCommon'
import { Empty } from 'antd'
import tinycolor from 'tinycolor2'

const EventListModal = () => {

  const visible = useDelegateStore((state) => state.visible);
  const setVisible = useDelegateStore((state) => state.setVisible);

  const [result, setResult] = useState([])

  const eventsList = eventQueries.useUpcomingEvent(
    (response) => {
      setResult(response)
    }
  );

  useEffect(() => {
    eventsList.mutateAsync()
  }, []);

  return (
    <div>
      <Modal
        closeButton
        aria-labelledby="modal-title"
        open={visible}
        onClose={() => setVisible(false)}
        width='80%'
      >
        <Modal.Header>
          <p className='text-[18px] font-medium'>Delegate visits / Upcoming webinars and Seminars</p>
        </Modal.Header>
        <Modal.Body>
          <div>
            {
              result?.data?.length === 0 ?
              <div className="my-[100px] text-center flex justify-center">
                  <Empty description={<div className="text-[14px] font-medium text-blue-700">Sorry! No events found.</div>} />
              </div>
              :
              <Fragment>
                  {eventsList?.isLoading ? <LoaderCommon /> :
                    <div className='grid grid-cols-12 max-[1290px]:grid-cols-8 bg-white rounded-[10px]'>
                      {
                        result?.data?.map((element, index) => (
                          <div
                          key={index}
                          style={{
                            backgroundColor: element?.color  ? element?.color : '#FFF',
                            color: tinycolor(element?.color).isDark() ? '#FFF' : '#000',
                          }}
                          className='relative col-span-4 py-[30px] px-[20px] border border-[#ecf3fa] rounded-xl m-2'
                          >
                              <h3 className='font-semibold mb-2'>{element?.name}</h3>
                              <span className='bg-green-700 text-white px-[10px] py-[4px] rounded-[5px] text-[12px]'>
                                {element?.type === "DELEGATE" ? "Delegate visit" : element?.type === "WEBINAR" ? "Webinar" : element?.type === "SEMINAR" ? "Seminar" : "Loan Mela"}
                              </span>
                              {
                                element?.country &&
                                <div className='mt-3 flex items-center gap-3'>
                                    <span><IconWorld color="#433878" size={16} /></span>
                                    <span className='text-[12px]'>{element?.country?.name} {element?.college?.name && `(${element?.college?.name})` }</span>
                                </div>
                              }
                              {
                                element?.venue &&
                                <div className='mt-2 flex items-center gap-3'>
                                    <span><IconMapPin color="#72BF78" size={16} /></span>
                                    <span className='text-[12px]'>{element?.venue}</span>
                                </div>
                              }
                              {
                                element?.office &&
                                <div className='mt-2 flex items-center gap-3'>
                                    <span><IconBuilding color="#EC8305" size={16} /></span>
                                    <span className='text-[12px]'>{element?.office?.name}</span>
                                </div>
                              }
                              <div className='mt-2 flex items-center gap-3'>
                                  <span><IconCalendar color="#87A2FF" size={16} /></span>
                                  <span className='text-[12px]'>{moment(element?.date).format('DD MMM YYYY, dddd')}</span>
                              </div>
                              <div className='mt-2 flex items-center gap-3'>
                                  <span><IconClock color="#B8001F" size={16} /></span>
                                  <span className='text-[12px]'>{moment(element?.startTime, "hh:mm A").format('hh:mm A')} - {moment(element?.endTime, "hh:mm A").format('hh:mm A')}</span>
                              </div>
                              <hr className='my-3' />
                              <div className='mt-2 flex items-center gap-3'>
                                  <span className='text-[12px]'>{element?.details}</span>
                              </div>
                          </div>
                        ))
                      }
                    </div>
                  }
              </Fragment>
            }
          </div>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>

    </div>
  )
}

export default EventListModal

function getTextColor(bgColor) {
  const r = parseInt(bgColor.substring(1, 3), 16);
  const g = parseInt(bgColor.substring(3, 5), 16);
  const b = parseInt(bgColor.substring(5, 7), 16);
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness < 125 ? '#FFF' : '#000';
}