import React, { Fragment } from 'react'
import { userStore } from '../../store/userStore'
import { useNavigate } from 'react-router-dom'

const ApplicantEditButton = ({item, followup}) => {

    const store = userStore()
    const navigate = useNavigate()
    
    const handleChange = async() => {
      await store.setApplication(item?.id)
      if(store?.user?.role === "MARKETINGASSOCIATE"){
          navigate('/addlead')
      }else{
        navigate('/addclient')
      }
    }

  return (
    <Fragment>
        <button
        disabled={(followup && item?.assignUser?.counselor !== store?.user?.id) ? true : false}
        onClick={handleChange} 
        className='btn-customized'
        style={{backgroundColor: (followup && item?.assignUser?.counselor !== store?.user?.id) && "#ccc"}}
        >
        Edit
        </button>
    </Fragment>
  )
}

export default ApplicantEditButton
