import eventService from "../services/eventService";
import { useMutation } from "react-query";

const useEventList = (onData) => {
  return useMutation(eventService.eventList, {
    onSuccess: (data) => onData(data),
  })
}

const useEventCreate = (onData) => {
  return useMutation(eventService.createEvent, {
    onSuccess: (data) => onData(data),
  })
}

const useEventUpdate = (onData) => {
  return useMutation(eventService.updateEvent, {
    onSuccess: (data) => onData(data),
  })
}

const useEventDelete = (onData) => {
  return useMutation(eventService.deleteEvent, {
    onSuccess: (data) => onData(data),
  })
}

const useEventView = (onData) => {
  return useMutation(eventService.viewEvent, {
    onSuccess: (data) => onData(data),
  })
}

const useUpcomingEvent = (onData) => {
  return useMutation(eventService.upcomingEvent, {
    onSuccess: (data) => onData(data),
  })
}

const useMasterDropdown = (onData) => {
  return useMutation(eventService.masterDropdown, {
    onSuccess: (data) => onData(data),
  })
}

const eventQueries = {
  useEventList,
  useEventCreate,
  useEventUpdate,
  useEventDelete,
  useEventView,
  useUpcomingEvent,
  useMasterDropdown
}

export default eventQueries