import { Modal } from '@nextui-org/react';
import React, { Fragment, useState } from 'react'
import toast from 'react-hot-toast';
import ProspectStatusList from '../../../Common/ProspectStatusList';
import moment from 'moment';
import { decode } from 'html-entities';
import LeadStatus from '../../../Common/LeadStatus';
import reportQueries from '../../../../queries/reportQueries';

const DistributionApplicantDetails = ({item, counselor, dateRange, fromDate, toDate, type, header, status, office}) => {

    const [isOpen, setIsOpen] = useState(false);
    const [result, setResult] = useState([])

    const handleOpen = () => {
        if(item > 0){
            setIsOpen(true)
            getData.mutateAsync({
                counselor,
                range: dateRange,
                from: fromDate,
                to: toDate,
                type,
                status,
                office
            })
        }else{
            toast.error("No Data Found")
        }
    }

    const getData = reportQueries.useDistributionReportCounselorWiseApplicants(
        (res)=>{
            if(res?.success){
                setResult(res?.data)
            }
        }
    )

    const handleClose = () => {
        setIsOpen(false)
    }

    const counselorName = (element)=>{
        const detail = element?.CounselorAssign?.userdetail
        if(!detail) return 'N/A'
        return detail?.firstname + ' ' + detail?.lastname
    }

  return (
    <Fragment>
        <button className=' text-blue-800' onClick={handleOpen}>
            {item}
        </button>
        <Modal
        width="90%"
        scroll
        closeButton
        blur
        aria-labelledby="modal-title"
        open={isOpen}
        onClose={handleClose}
        >
            <Modal.Header>
                <span className='text-[16px] font-medium'>{header}</span>
            </Modal.Header>
            <Modal.Body>
                <div className="table-wrpr">
                    <div className="table-section">
                        <div className="row">
                            <div className="col-md-12">
                                <table className="table prospect-table mb-0">
                                    <thead>
                                        <tr className='text-[13px]'>
                                            <th width="30">#</th>
                                            <th>Profile Status</th>
                                            <th>Office</th>
                                            <th>Date Created</th>
                                            <th>Applicant Name</th>
                                            <th>Contact Number</th>
                                            <th>Visa Type</th>
                                            <th>Intake</th>
                                            <th>Counselor</th>
                                            <th>Reg. Date</th>
                                            <th>Prospect Status</th>
                                            <th>Reffered By</th>
                                        </tr>
                                    </thead>
                                    <tbody className="ui-sortable">
                                        {result?.map((item, index) => (
                                            <Fragment key={index}>
                                                <tr className="ui-sortable-handle text-[11px]">
                                                    <td>{index + 1}</td>
                                                    <td><LeadStatus item={item} /></td>
                                                    <td>{item?.office?.name}</td>
                                                    <td>{moment(item?.createdAt).format('DD-MM-YYYY')}</td>
                                                    <td>
                                                        {decode(item?.applicant_name)} {decode(item?.middlename)} {decode(item?.lastname)}
                                                    </td>
                                                    <td>
                                                        <div className="email-mobile flex flex-col">
                                                            <div>{item?.mobile_no}</div>
                                                            <div>{item?.landline_no}</div>
                                                            <div>{item?.whatsapp_no}</div>
                                                        </div>
                                                    </td>
                                                    <td>{item?.visatype?.description}</td>
                                                    <td>{item?.intake?.intake}</td>
                                                    <td>
                                                        <div>{counselorName(item?.assignUser)}</div>
                                                    </td>
                                                    <td>
                                                        {
                                                            item.registered_date ?
                                                            <span className='registered'>
                                                                {item.registered_date && moment(item?.registered_date).format('DD-MM-YYYY')}
                                                            </span>:
                                                            "-"
                                                        }
                                                    </td>
                                                    <td>
                                                        <ProspectStatusList item={item}/>
                                                    </td>
                                                    <td>{item?.refferal?.refferal}</td>
                                                </tr>
                                            </Fragment>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer />
        </Modal>
    </Fragment>
  )
}

export default DistributionApplicantDetails
