import React, { Fragment, useEffect, useState } from 'react'
import $ from "jquery";
import Header from './Header';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { usePaginationStore, userStore } from '../../../store/userStore';
import RouteConstants from '../../../Constants/RouteConstans';
import { NavigationRoleWise, NavigationMaster, NavigationReport, NavigationUsaProgram } from '../../../Data/NavigationData';
import { useAdvancedSearch } from '../../../store/advancedSearchStore';

const Sidebar =()=> {

    const user = userStore()
    const navigate = useNavigate()

    const location = useLocation();
    const currentRoute = location.pathname;
    const resetAll = useAdvancedSearch((state)=> state.resetAll)
    let visaArray = []
    user?.user?.visa?.map((visa) => {visaArray.push(visa?.vid)})
    $(document).on('click', '.goto-mastermenu', function(e){
        e.preventDefault();
        $('.wrapper').toggleClass('mastermenu-active');	
    });
       
    $(document).on('click', '.backto-mainmenu', function(e){
        e.preventDefault();
        $('.wrapper').toggleClass('mastermenu-active');	
    });


    $(document).ready(function () {
        $('.navbar-toggler-icon').on('click', function(){
            $('.navbar-toggler-icon').toggleClass('active');
            $(this).parents('.wrapper').toggleClass('sidebar-collapsed');
            $(this).parents('.wrapper').toggleClass('sidebar-active');
        })
    });

    const [toggle,  setToggle] = useState(user?.toggle ? true: false)

    useEffect(()=> {

        if(user?.toggle){
            setToggle(true)
        }else{
            setToggle(false)
        }
    },[user?.toggle])

    const resetPage = usePaginationStore((state) => state.resetPage);

    const handleClick =(item)=>{
        resetAll()
        if(item.masterItem){
            resetPage()
            navigate(item.url)
            return
        }
        if(item.reportItem){
            resetPage()
            navigate(item.url)
            return
        }
        if(item?.anchor){
            return window.open(item.url, '_blank')
        }
        if(item?.master){
            return user.setActiveTab("/master")
        }
        if(item?.report){
            return user.setActiveTab("/report")
        }
        resetPage()
        user.setActiveTab(item.url)
        navigate(item.url)
    }


  return (
    <div className={!NavigationRoleWise?.[user.user.role] ? 'application-staff-page':''}>
        <div className={`wrapper ${toggle ? 'sidebar-active' : 'sidebar-collapsed' }`}>
            <aside className="left-side side-off-canvas">
                <section className="sidebar">
                    {
                        !toggle ?
                        <div className='flex justify-center flex-col items-center pt-3 gap-5'>
                            <a className='text-[18px] text-white cursor-pointer' onClick={()=> user.setToggleMenu(true)}><i className="icon-menu1"></i></a>
                            {(user?.activeTab === "/master" || user?.activeTab === "/report") &&
                                <a className='text-[18px] text-white flex items-center cursor-pointer' onClick={()=> user.setActiveTab("")}>
                                    <i className="icon-keyboard_arrow_left"></i>
                                </a>
                            }
                        </div>:
                        <div className='flex justify-between p-3 pr-3 cursor-pointer'>
                            <a className='text-[18px] text-white flex items-center' onClick={()=> user.setActiveTab("")}>
                                {(user?.activeTab === "/master" || user?.activeTab === "/report") &&
                                    <Fragment>
                                        <i className="icon-keyboard_arrow_left"></i>
                                        <div className='text-[12px]'>Back</div>
                                    </Fragment>
                                }
                            </a>
                            <a className='text-[18px] text-white' onClick={()=> user.setToggleMenu(false)}><i className="icon-close"></i></a>
                        </div>
                    }
                    {
                        user?.activeTab === "/master" ?
                        <nav className="navbar master-menu">
                            <ul className="sidebar-menu navbar-nav">
                                {
                                    NavigationMaster?.sort((a, b) => a?.label?.localeCompare(b.label))?.map((item, index)=> (
                                        <li className="nav-item" key={index}>
                                            <a className={`nav-link ${currentRoute === item.url && 'active'} cursor-pointer`} onClick={()=>handleClick(item)}><span>{item?.label}</span> <i className={`fontSize18 ${item.icon}`}></i></a>
                                        </li>
                                    ))
                                }
                            </ul>
                        </nav>:
                        user?.activeTab === "/report" ?
                        <nav className="navbar master-menu">
                            <ul className="sidebar-menu navbar-nav">
                                {
                                   // NavigationReport?.sort((a, b) => a?.label?.localeCompare(b.label))?.map((item, index)=> {
                                    NavigationReport?.map((item, index)=> {
                                        if((item?.logins.includes(user?.user?.rid) || item?.allLogins) || user?.user?.role == "CEO")
                                        return(
                                            <li className="nav-item" key={index}>
                                                <a className={`nav-link ${currentRoute === item.url && 'active'} cursor-pointer`} onClick={()=>handleClick(item)}><span>{item?.label}</span> <i className={`fontSize18 ${item.icon}`}></i></a>
                                            </li>
                                        )
                                        else{
                                            return null
                                        }
                                    })
                                }
                            </ul>
                        </nav>:
                        <nav className="navbar main-menu">
                            <ul className="sidebar-menu navbar-nav">   
                                {
                                    NavigationRoleWise?.[user?.user?.role]?.map((item, index)=> (
                                        <li className="nav-item" key={index}>
                                            {
                                                item?.master ?
                                                <a className={`nav-link ${user.activeTab === item.url && 'active goto-mastermenu'} cursor-pointer`} onClick={()=>{handleClick(item)}}>
                                                    <span>{item?.label}</span>
                                                    <i className={`fontSize18 ${item.icon}`}></i>
                                                </a>:
                                                item?.usaProgram?
                                                (
                                                    user?.user?.usaprogram === "YES"&&
                                                    <a className={`nav-link ${user.activeTab === item.url && 'active'} cursor-pointer`} onClick={()=>{handleClick(item)}}>
                                                        <span>{item?.label}</span> 
                                                        <i className={`fontSize18 ${item.icon}`}></i>
                                                    </a>
                                                ):
                                                <a className={`nav-link ${user.activeTab === item.url && 'active'} cursor-pointer`} onClick={()=>{handleClick(item)}}>
                                                    <span>{item?.label}</span>
                                                    <i className={`fontSize18 ${item.icon}`}></i>
                                                </a>
                                            }
                                        </li>
                                    ))
                                }
                                
                            </ul>
                        </nav>
                    }
                </section>
            </aside>
            <Header />
        </div>
    </div>
  )
}

export default Sidebar
