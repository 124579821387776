import React, { useEffect } from 'react'
import { userStore } from '../store/userStore'
import { useNavigate } from 'react-router-dom'
import { socket } from './socket'
import logoutQueries from '../queries/logoutQueries'

const SocketLogout = () => {

    const storeData = userStore()
    const navigate = useNavigate()

    const logOutMutation =()=>{
        storeData?.setBranch({counselor:'', status:''})
        storeData.setUser({})
        navigate("/")
    }

    useEffect(() => {
        socket.on('logout', (message) => {
            if(message){
                logOutMutation()
            }
        });
    }, []);

    const verifyUser = logoutQueries.useVerifyUser(
        (res)=>{
            if(!res.status){
                logOutMutation()
            }
        }
    )

    useEffect(() => {
        verifyUser.mutateAsync()
    }, []);

  return (
    <div>
      
    </div>
  )
}

export default SocketLogout