import followupService from "../services/followupService";
import { useMutation } from "react-query";

const useFollowupPending = (oData) => {
  return useMutation(followupService.getFollowupPending, {
    onSuccess: (data) => oData(data),
  })
}

const followupQueries = {
    useFollowupPending
}

export default followupQueries