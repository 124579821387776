import React, { useState } from 'react'
import { Fragment } from 'react'
import { InboxOutlined, DeleteOutlined, EyeOutlined, DownloadOutlined } from '@ant-design/icons';
import { Alert, List, Upload, message } from 'antd';
import authQueries from '../../queries/authQueries';
import { Modal } from '@nextui-org/react';
import { EyeIcon } from 'lucide-react';
import { IconCloudDownload, IconTrash } from '@tabler/icons-react';
import toast from 'react-hot-toast';
import { decode } from 'html-entities';
import LoaderCommon from './LoaderCommon';

const DocumentUploadApplicant = ({item}) => {

    const [open, setOpen] = useState(false)
	const [openAlert, setOpenAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")
    const [reason, setReason] = useState("")
    const [loader, setLoader] = useState(false)

    const handleOpen =()=> {
        setOpen(true)
    }

    const handleClose =()=> {
        setOpen(false)
    }

    const uploadFile = authQueries.usePdfUploadCommon(
        (response) => {
            if(response?.message === "success"){
                let dataValues = {
                    applicant_id: item?.id,
                    reason,
                    file: response?.file,
                    status:"ACTIVE"
                }
                addDocument.mutateAsync(dataValues)
            }

            if(response?.response?.data?.message === "File type not supported. Only pdf, jpeg, jpg, png and gif files are supported.")
                message.error(response?.response?.data.message)
            }   
    );


    const addDocument = authQueries.useApplicantFileAdd(
        (response) => {
            if(response?.message === "Success"){
                handleClose()
                toast.success('Document uploaded successfully')
                setReason("")
                setLoader(false)
            }
        }
    );


	const handleUpload =(e)=> {

        if(reason === ""){
            setAlertMessage("Please enter reason")
            setOpenAlert(true)
            return
        }

		if(e.file.type !== "application/pdf"){
            setAlertMessage("File type not supported. Only pdf")
            setOpenAlert(true)
			return
		}

		if(e.file.status === 'uploading'){
            setLoader(true)
			const data = new FormData();
			data.append('file', e.file.originFileObj)
            data.append('name', `${e.file.name}`)
			uploadFile.mutateAsync(data)
		}
	};

	const handleDrop =(e)=> {
		if(e.file.type !== "application/pdf"){
            setAlertMessage("File type not supported. Only pdf")
            setOpenAlert(true)
			return
		}

		if(e.file.status === 'uploading'){
            setLoader(true)
			const data = new FormData();
			data.append('file', e.file.originFileObj)
            data.append('name', `${e.file.name}`)
			uploadFile.mutateAsync(data)
		}
	};

	const customRequest = ({ file, onSuccess, onError }) => {
		setTimeout(() => {
		  onSuccess('ok');
		}, 1000);
	};

    const closeAlert =()=> {
        setOpenAlert(false)
    }

  return (
    <Fragment>
        <button onClick={handleOpen} className='btn-customized'>Document Upload</button>
        <Modal
        closeButton
        blur
        scroll
        width="50%"
        aria-labelledby="modal-title"
        open={open}
        onClose={handleClose}
        >
            <Modal.Header>					
                <div className='font-semibold text-[16px]'>Uploaded History</div>
            </Modal.Header>
            <Modal.Body>
                {
                    loader ?
                    <LoaderCommon />:
                    <Fragment>
                        {
                            openAlert &&
                            <Alert message={alertMessage} type="error" closable afterClose={closeAlert} />
                        }
                        <input
                        onChange={(e)=>{setReason(e.target.value)}}
                        value={reason}
                        className='bg-gray-50 border border-gray-300 text-gray-900 rounded-lg pl-3 focus-within:outline-blue-400 h-[45px] w-full'
                        placeholder="Reason for document upload"
                        />
                        <Upload.Dragger customRequest={customRequest} showUploadList={false} onDrop={handleDrop} onChange={handleUpload}>
                            <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">
                                Support only pdf files
                            </p>
                        </Upload.Dragger>
                    </Fragment>
                }
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    </Fragment>
  )
}

export default DocumentUploadApplicant
