import logoutService from "../services/logoutService";
import { useMutation } from "react-query";
import secondaryRefferalService from "../services/secondaryRefferalService";

const useGetRefferal = (onData) => {
  return useMutation(secondaryRefferalService.getRefferal, {
    onSuccess: (data) => onData(data),
  })
}

const useCreateRefferal = (onData) => {
  return useMutation(secondaryRefferalService.createRefferal, {
    onSuccess: (data) => onData(data),
  })
}

const useUpdateRefferal = (onData) => {
  return useMutation(secondaryRefferalService.updateRefferal, {
    onSuccess: (data) => onData(data),
  })
}

const useDeleteRefferal = (onData) => {
  return useMutation(secondaryRefferalService.deleteRefferal, {
    onSuccess: (data) => onData(data),
  })
}

const usePrimaryRefferalDropdown = (onData) => {
  return useMutation(secondaryRefferalService.primaryRefferalDropdown, {
    onSuccess: (data) => onData(data),
  })
}

const secondaryRefferalQueries = {
  useGetRefferal,
  useCreateRefferal,
  useUpdateRefferal,
  useDeleteRefferal,
  usePrimaryRefferalDropdown
}

export default secondaryRefferalQueries