import React, { useState } from 'react'
import { Fragment } from 'react'
import { InboxOutlined, DeleteOutlined, EyeOutlined, DownloadOutlined } from '@ant-design/icons';
import { Alert, List, Upload, message } from 'antd';
import authQueries from '../../queries/authQueries';
import { Modal } from '@nextui-org/react';
import { EyeIcon } from 'lucide-react';
import { IconCloudDownload, IconTrash } from '@tabler/icons-react';
import toast from 'react-hot-toast';
import { decode } from 'html-entities';

const DocumentUploadModal = ({item}) => {

    const [open, setOpen] = useState(false)
	const [fileList, setFileList] = useState([])
	const [openAlert, setOpenAlert] = useState(false)
    const [alertMessage, setAlertMessage] = useState("")

    const handleOpen =()=> {
        programDocList.mutateAsync(item?.id)
        setOpen(true)
    }

    const handleClose =()=> {
        setOpen(false)
    }

    const programDocList = authQueries.useProgramDocumentListMutation(
        async (res) => {
			if(res?.message === 'success'){
				setFileList(res?.data)
			}
		}
    )

	const programDocumentCreate = authQueries.useProgramDocumentCreateMutation(
		async (res) => {
			if(res?.message === 'success'){
				setFileList(res?.data)
			}
		}
	)

    const uploadFile = authQueries.usePdfUploadCommon(
        (response) => {
            if(response?.message === "success"){
                const name = response?.file?.substring(response?.file?.lastIndexOf('/') + 1)
				const newData = {
					name: name,
					file: response?.file,
					pid: item.id,
				}
				programDocumentCreate.mutateAsync(newData)
            }

            if(response?.response?.data?.message === "File type not supported. Only pdf, jpeg, jpg, png and gif files are supported.")
                message.error(response?.response?.data.message)
            }   
    );

	const handleUpload =(e)=> {
		if(e.file.type !== "application/pdf"){
            setAlertMessage("File type not supported. Only pdf")
            setOpenAlert(true)
			return
		}

		if(e.file.status === 'uploading'){
			const data = new FormData();
			data.append('file', e.file.originFileObj)
            data.append('name', `${e.file.name}`)
			uploadFile.mutateAsync(data)
		}
	};

	const handleDrop =(e)=> {
		if(e.file.type !== "application/pdf"){
            setAlertMessage("File type not supported. Only pdf")
            setOpenAlert(true)
			return
		}

		if(e.file.status === 'uploading'){
			const data = new FormData();
			data.append('file', e.file.originFileObj)
            data.append('name', `${e.file.name}`)
			uploadFile.mutateAsync(data)
		}
	};

	const customRequest = ({ file, onSuccess, onError }) => {
		setTimeout(() => {
		  onSuccess('ok');
		}, 1000);
	};

	const handleViewPDF = async (e) => {
        const file = decode(e)
        window.open(file, "_blank")    
	}  

	const programDocumentDelete = authQueries.useProgramDocumentDeleteMutation(
		async (res) => {
			if(res?.message === 'success'){
				setFileList(res?.data)
			}
		}
	)

	const handleDocumentDelete =(id)=> {
		programDocumentDelete.mutateAsync(id)
	}

    const closeAlert =()=> {
        setOpenAlert(false)
    }

  return (
    <Fragment>
        <button onClick={handleOpen} className='btn-customized'>Offer/Docs</button>
        <Modal
        closeButton
        blur
        scroll
        width="50%"
        aria-labelledby="modal-title"
        open={open}
        onClose={handleClose}
        >
            <Modal.Header>					
                <div className='font-semibold text-[16px]'>Uploaded History</div>
            </Modal.Header>
            <Modal.Body>
                {
                    openAlert &&
                    <Alert message={alertMessage} type="error" closable afterClose={closeAlert} />
                }
                <Upload.Dragger customRequest={customRequest} showUploadList={false} onDrop={handleDrop} onChange={handleUpload}>
                    <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">
                        Support only pdf files
                    </p>
                </Upload.Dragger>
                {
                    fileList?.length > 0 &&
                    <List
                        locale={{emptyText: ' '}}
                        dataSource={fileList}
                        renderItem={(item) => (
                            <List.Item>
                                <div className='pdf-list-container'>
                                    <div style={{display:'flex', alignItems:'center'}}>
                                        <img style={{width:'60px'}} src={require('../../assets/images/pdficon2.png')} alt="pdficon" />
                                        <div>{item?.name}</div> 
                                    </div>
                                    <div className='flex pr-[10px] items-center gap-4'>
                                        <button
                                        className='border border-gray-200 p-2 rounded-md'
                                        onClick={()=> {handleViewPDF(item?.file)}}>
                                            <EyeIcon size="20" color="gray" />
                                        </button>
                                        <button
                                        className='border border-gray-200 p-2 rounded-md'
                                        onClick={()=> handleDocumentDelete(item?.id)}>
                                            <IconTrash size="20" color="red" />
                                        </button>
                                    </div>
                                </div>
                            </List.Item>
                        )}
                    />
                }
            </Modal.Body>
            <Modal.Footer>
                <span>&nbsp;</span>
            </Modal.Footer>
        </Modal>
    </Fragment>
  )
}

export default DocumentUploadModal
