import React, { Fragment, useEffect, useState } from 'react'
import { Link, Outlet, useNavigate } from 'react-router-dom'
import { userStore } from '../../../store/userStore'
// import { Grid, Dropdown, Radio } from "@nextui-org/react";
import { Modal, Popover } from 'antd';
import $ from "jquery";
import { Avatar } from '@nextui-org/react';
import authQueries from '../../../queries/authQueries';
import NotificationDrawer from '../NotificationComponent';
import SearchAllApplicant from '../../Clients/ApplicantAssign/SearchAllApplicant';
import MenuItems from '../MenuItems';
import ColorSheme from '../../ColorScheme/ColorSheme';
import RouteConstants from '../../../Constants/RouteConstans';
import { useRmStore } from '../../../store/rmRoleStore';
import UpdateProfileImage from '../UpdateProfileImage';
import { useExistModal } from '../../../store/existModalStore';
import { IconExclamationMark } from '@tabler/icons-react';
import { IconExclamationCircle } from '@tabler/icons-react';
import EventListModal from '../EventListModal';

const Header =()=> {

	const user = userStore();
	const navigate = useNavigate();

	const setOffice = useRmStore((state) => state.setOffice);
	const office = useRmStore((state) => state.office);

	const setImportant = useExistModal((state) => state.setImportant);

	useEffect(()=>{
		if(!user?.user?.accessToken){
			navigate("/")
		}
		let dataArr = []
		user?.user?.srole?.map((item) => {
			const data = {
				label: item.role.label,
				role: item.role.name,
				key: item.role.id

			}
			dataArr = [...dataArr, data]
		})
	},[])


	const leaderBoard = authQueries.useLeaderBoardCount(
		(response) => {
		}
	)
	
	const leaderBoardData =()=> {
		leaderBoard.mutateAsync()
	}

	const logOutMutation =()=>{
		user?.setBranch({counselor:'', status:''})
		user.setUser({})
		navigate("/")
	}


	const newNav = () => {

		$('.navbar-toggler-icon').on('click', function(){
			$('.navbar-toggler-icon').toggleClass('active');
			$(this).parents('.wrapper').toggleClass('sidebar-collapsed');
			$(this).parents('.wrapper').toggleClass('sidebar-active');
		})

		/* master menu */
		$(document).on('click', '.goto-mastermenu', function(e){
			e.preventDefault();
			$('.wrapper').toggleClass('mastermenu-active');	
		});
		
		$(document).on('click', '.backto-mainmenu', function(e){
			e.preventDefault();
			$('.wrapper').toggleClass('mastermenu-active');	
		});

	}


	const switchRole =(role, label, id)=>{
		user.user.role = role
		user.user.rolelabel = label
		user.user.rid = id
		user.setUser({ ...user.user });
		navigate("/dashboard")
		newNav();
	}

	const [open, setOpen] = useState(false)
	
	const showDrawer = () => {
		setOpen(true);
		setImportant("NO");
	};

	const showDrawerImportant = () => {
		setOpen(true);
		setImportant("YES");
	};

	const gotoCalendar = () => {

		let isAdmin = false
		if(user.user){
			const isAdminStatus = user.user?.srole?.find(item => item?.role?.name === "ADMIN")
			if(isAdminStatus){
				isAdmin = true
			}

			const userData = {
				...user.user,
				isAdmin: isAdmin
			}

			localStorage.setItem("userData", JSON.stringify(userData))
		}
	}

	const [visible, setVisible] = useState(false)

	const handleModal =()=>{
		setVisible(true)
	}

	const handleClose =()=>{
		setVisible(false)
	}

	const addApplicant = async () => {
        await user.setApplication('')
        user.setApplicationAllData({})
		if(user.user.role === "MARKETINGASSOCIATE"){
			navigate('/addlead')
		}else{
			navigate('/addclient')
		}
    }
      

  return (
    <Fragment>
        <aside className="right-side">
			<header className='header-main'>
				<div className='flex justify-between w-full'>
					<div className="logo-wrpr flex items-center gap-12">
						<div className='hidden max-toggleScreen:block'>
							<div onClick={()=>user.setToggleMenu(true)} className='icon-menu1 text-[25px] text-gray-400 cursor-pointer'></div>
						</div>
						<img className='max-[573px]:hidden' src={require('../../../assets/images/logo2.png')} width="60px" height="" alt="" />
					</div>

					<div className="right-cntnr">

						{
							(user?.user?.role === "COUNSELOR" || user?.user?.role === "TELECALLER" || user?.user?.role === "MARKETINGASSOCIATE" || user?.user?.role === "SENIORPROGRAMMANAGER" || user?.user?.role === "CEO" || user?.user?.role === "RM" || user?.user?.role === "MANAGER" || user?.user?.role === "BMANAGER") &&
							<div className="search-wrpr advanced-search-wrpr" style={{minWidth:'110px'}}>
								<a style={{cursor:'pointer'}} onClick={addApplicant}>Add Client&nbsp;&nbsp;<img style={{width:'18px', height: "15px"}} src={require('../../../assets/images/add-client.png')} alt="" /></a>
							</div>
						}

						{user.user.role ==="PROGRAMCOORDINATOR" || user.user.role ==="ADMIN" || user.user.role ==="MARKETINGASSOCIATE" || user.user.role ==="TELECALLER" || user.user.role ==="ADMISSIONSTAFF" || user.user.role ==="BDM" || user.user.role ==="FEECOORDINATOR"  ?
						null:
							<div className="dropdown max-[563px]:hidden">
								<MenuItems handleClick={leaderBoardData} data={leaderBoard} />
							</div>
						}

						<div className="search-wrpr advanced-search-wrpr">
							<a style={{cursor:'pointer'}} onClick={handleModal}>All Clients Search <i className="icon-search"></i></a>
						</div>

						<div className="notification-wrpr dropdown max-[563px]:hidden">
							<a onClick={showDrawer} style={{cursor:'pointer'}} data-notifications={user?.notificationCount?.find(item => item.id === user.user.id) ? user.notificationCount.find(item => item.id === user.user.id).count : 0}><i className="icon-notifications"></i></a>
						</div>
						
						<div className="notification-wrpr dropdown max-[563px]:hidden">
							<a onClick={showDrawerImportant} style={{cursor:'pointer'}} data-notifications={user?.notificationCount?.find(item => item.id === user.user.id) ? user.notificationCount.find(item => item.id === user.user.id).count2 : 0}><IconExclamationCircle className="icon-notifications !text-red-600"></IconExclamationCircle></a>
						</div>

						<div className="calendar-wrpr dropdown max-[563px]:hidden">
							<a href='/calendar' onClick={gotoCalendar} style={{cursor:'pointer'}}><i className="icon-calendar1"></i></a>
						</div>

						<div className="myprofile-wrpr namd-desig-cntnr dropdown max-[563px]:hidden" aria-expanded="false">
							
							<div className="myprofile-name-desig" style={{cursor: 'pointer'}}>
								<div className="name">{user?.user?.name}</div>
								<div className="desig">
									{(user?.user?.role !== "MANAGER" && user?.user?.role !== "BMANAGER" && user?.user?.role !== "RM" ) && <Fragment> {user.user.rolelabel}</Fragment>}
									{
										user?.user?.role === "MANAGER" && 
										<Fragment>
											{user?.user?.visa?.length > 1 ? 
												<Popover content={
													<div>
														{user?.user?.visa.map((item, index) => (
															<div key={index}>
																{item?.visatype?.description}
															</div>
														))}
													</div>
												}>
													<span>
														{user.user.rolelabel}
													</span>
												</Popover>: 
												`${user.user.rolelabel} (${user?.user?.visa[0]?.visatype?.visacode})`
											}
										</Fragment>
									}
									{
										(user?.user?.role === "BMANAGER" || user?.user?.role === "RM") && 
										<Fragment>
											{user?.user?.office?.length > 1 ? 
												<Popover content={
													<div>
														{user?.user?.office.map((item, index) => (
															<div 
															key={index} 
															onClick={()=>setOffice(item?.oid)} 
															className={`${user?.user?.role === "RM" && "cursor-pointer"} ${office === item?.oid ? "text-blue-500" : ""}`}
															>
																{item?.office?.name}
															</div>
														))}
													</div>
												}>
												<div>
													{user.user.rolelabel} 
												</div>
												</Popover> :
												`${user.user.rolelabel}`
											}
										</Fragment>
									}
								</div>
							</div>

							{user?.user?.image ?
								<Avatar
									style={{cursor: 'pointer'}}
									className="dropdown-toggle" data-toggle="dropdown"
									size="lg"
									src={`${user?.user?.image}`}
									bordered
								/>:
								<a href="#" className="dropdown-toggle" data-toggle="dropdown">
									<i className="icon-person"></i>
								</a>
							}
							

							<div className="dropdown-menu newUI dropdown-menu-right">
								{
									user?.user?.srole?.length <= 1?null:
										<Fragment>
											{
												user?.user?.srole?.map((item, index) => ( 
													<Fragment key={index}> 
														<button
															style={{color:item?.isPrimary === "YES"?'green':'#000'}} 
															className='dropdown-item' 
															onClick={()=>switchRole(item.role.name, item?.role?.label, item?.role?.id)}>
															<span>
																{item?.role?.label} &nbsp;
																{
																	item?.isPrimary === "YES" &&
																	<span style={{
																	backgroundColor:'#DEEFEF',
																	borderRadius:'5px',
																	fontSize:'12px', 
																	padding:'2px'}}>&nbsp;&nbsp;&nbsp;primary&nbsp;&nbsp;&nbsp;</span>
																}
															</span>
														</button>
													</Fragment>
												))
											}
										</Fragment>
									}
								<button onClick={()=>{navigate(RouteConstants.RESETPASSWORD)}} className="dropdown-item">Reset Password</button>
								<UpdateProfileImage />
								<button onClick={logOutMutation} className="dropdown-item">Logout</button>
								{
									(user?.user?.role === "ADMIN" || user?.user?.role === "CEO") &&
									<button onClick={()=>{navigate(RouteConstants.LOGOUTALLUSER)}} className="dropdown-item">Logout All Users</button>
								}
							</div>
						</div>

					</div>
				</div>
			</header>

			<NotificationDrawer open={open} setOpen={setOpen} />
			
            <div className="content-area">
				<Outlet />
			</div>

			<Modal
			open={visible}
			width='80%'
			animated
			closeButton
			footer={null}
			closable
			onCancel={handleClose}
			title={<div className='text-[16px] text-center font-semibold'>All Clients Search</div>}
			>
				<SearchAllApplicant handleClose={handleClose} />
			</Modal>
		</aside>
    </Fragment>
  )
}

export default Header




