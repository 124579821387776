import React, { Fragment } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect } from 'react';
import { useViewProfile, userStore } from '../../store/userStore';
import moment from 'moment';
import { decode } from 'html-entities';
import { Button, Card, Image, Loading, Tooltip } from '@nextui-org/react';
import HOT from '../../assets/images/hot-icon-2.png'
import WARM from '../../assets/images/warm-icon-2.png'
import COLD from '../../assets/images/cold-icon-2.png'
import REG from '../../assets/images/registered-icon-2.png'
import LONG from '../../assets/images/longterm-icon-3.png'
import HOLD from '../../assets/images/on-hold-icon.png'
import authQueries from '../../queries/authQueries';
import $ from 'jquery'
import { Badge } from 'antd';
import LoaderCommon from '../Common/LoaderCommon';
import toast from 'react-hot-toast';
import { IconBrandGmail, IconBrandWhatsapp, IconMail } from '@tabler/icons-react';

const VisaStaffDetailPage = () => {

  const storeData = userStore().appAllData
  const store = userStore()
  const applicantId = useViewProfile((state)=> state.appid)
  const navigate = useNavigate()

  const backButton =()=> {
    navigate(-1)
  }

  const viewAllApplicationData = authQueries.useApplicationMutationViewAll(
    async (response) => {
        if (response.data) {
          await store.setApplicationAllData(response.data)
          oldDocuments.mutateAsync(response?.data?.applicant?.assessment_old)
        }
    }
  )

  useEffect (() => {
    viewAllApplicationData.mutateAsync(applicantId)
  }, [applicantId]);

  const oldDocuments = authQueries.useOldDocumentListing(
    (res) => {
    }   
  );

  const handleViewPDF = async(e) => {
    const file = decode(e)
    window.open(file, "_blank")
  }  


  const calculateAge = (dob) => {
    if (dob) {
      const birthDate = moment(dob)
      const today = moment()
      const ageInYears = today.diff(birthDate, 'years');
      return ageInYears
    }
  }

  const downloadOldFile =(url)=>{
    if(url){
        window.open(`${url}`, "_blank")
    }else{
        toast.error("File not uploaded.")
    }
  }

  return (
   
    <div>

				<div className="breadcrumb-and-otherbuttons">
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><Link to="/dashboard">Home</Link></li>
                    <li className="breadcrumb-item"><button onClick={backButton}>My Clients</button></li>
                    <li className="breadcrumb-item active" aria-current="page">Applicant Details</li>
                </ol>
            </nav>
            <nav aria-label="breadcrumb">
              <ol>
                <li className="breadcrumb-item"><button onClick={backButton}>Close</button></li>
              </ol>
            </nav>
				</div>

        {viewAllApplicationData?.isLoading ?
          <div className='flex items-center justify-center mt-[10%]'>
            <LoaderCommon />
          </div>
            :
            <div className="prg-coordinator">
              <div  className="add_clients_accordion_form">

                <div className="card" >
                  <div className="card-header">
                    <a className="collapsed card-link"  href="" style={{pointerEvents:'none'}}>
                      Applicant Details
                    </a>
                  </div>
                  <div id="appointment_any" className="collapse show" data-parent="#accordion">
                    <div className="row details-page-wrpr" style={{marginBottom:'30px'}}>
                      <div className="col-md-12 text-center big-fonts profile-name-phone-wrpr" style={{margin:'30px'}}>
                          <div className="profile-wrpr">
                            <div
                            id="image-preview"
                            style={{
                              backgroundImage:storeData?.applicant?.photo ? 
                              `url(${storeData?.applicant?.photo})`:
                              `url(${require('../../assets/images/profile-avatar.jpg')})`, 
                              backgroundSize:'cover'
                            }}>
                            </div>
                          </div>
                          <span style={{fontSize:'18px', fontWeight: 500}}>{storeData?.applicant?.applicant_name} {storeData?.applicant?.middlename} {storeData?.applicant?.lastname}</span>
                          <span className="phonenumber"><a href="tel:+{storeData?.mobile_no}">{storeData?.applicant?.mobile_no && `+${storeData?.applicant?.mobile_no}`}</a></span>
                      </div>
                      
                      <div className='grid grid-cols-10 w-full max-lg:grid-cols-6 max-md:grid-cols-4 max-sm:grid-cols-2 max-sm:text-center'>
                            <div className="col-span-2 mb-4">
                              <label>GEEBEE Student ID</label>
                              <span style={{color:'#f00', fontWeight: 700}}>{storeData?.applicant?.geebee_student_id}</span>
                            </div>

                            <div className="col-span-2 mb-4">
                              <label>Email</label>
                              <span>{storeData?.applicant?.email}</span>
                            </div>

                            <div className="col-span-2 mb-4">
                              <label>Whatsapp No</label>
                              <span>{storeData?.applicant?.whatsapp_no}</span>
                            </div>

                            <div className="col-span-2 mb-4">
                              <label>Landline No</label>
                              <span>{storeData?.applicant?.landline_no}</span>
                            </div>
                        
                            <div className="col-span-2 mb-4">
                              <label>Visa Type</label>
                              <span>{storeData?.applicant?.visatype?.description}</span>
                            </div>

                            <div className="col-span-2 mb-4">
                              <label>Office</label>
                              <span>{storeData?.applicant?.office?.name}</span>
                            </div>

                            <div className="col-span-2 mb-4">
                              <label>Intake</label>
                              <span>{storeData?.applicant?.intake?.intake}</span>
                            </div>

                            <div className="col-span-2 mb-4">
                              <label>Refferal</label>
                              <span>{storeData?.applicant?.refferal?.refferal}</span>
                            </div>

                            <div className="col-span-2 mb-4">
                              <label>Secondary Refferal</label>
                              <span>{storeData?.applicant?.secondary_refferal?.name}</span>
                            </div>

                            <div className="col-span-2 mb-4">
                              <label>Gender</label>
                              <span className='profile-view'>{storeData?.applicant?.gender}</span>
                            </div>

                            <div className="col-span-2 mb-4">
                              <label>Marital Status</label>
                              <span className='profile-view'>{storeData?.applicant?.marital_status}</span>
                            </div>

                            <div className="col-span-2 mb-4">
                              <label>District</label>
                              <span className='profile-view'>{storeData?.applicant?.district?.name}</span>
                            </div>

                            <div className="col-span-2 mb-4">
                              <label>Pincode</label>
                              <span>{storeData?.applicant?.pincode}</span>
                            </div>

                            <div className="col-span-2 mb-4">
                              <label>Address</label>
                              <span>{storeData?.applicant?.address}</span>
                            </div>

                            <div className="col-span-2 mb-4">
                              <label>Occupation</label>
                              <span>{storeData?.applicant?.occupation}</span>
                            </div>

                            <div className="col-span-2 mb-4">
                              <label>Date of Birth</label>
                              <span>
                                {storeData?.applicant?.dob && moment(storeData?.applicant?.dob).format('DD-MM-YYYY')}
                              </span>
                            </div>

                            <div className="col-span-2 mb-4">
                              <label>Age</label>
                              <span>{calculateAge(storeData?.applicant?.dob)}</span>
                            </div>

                            <div className="col-span-2 mb-4">
                              <label>No of Children</label>
                              <span>{storeData?.applicant?.no_of_children}</span>
                            </div>

                            <div className="col-span-2 mb-4">
                              <label>Registered Date</label>
                              <span>{storeData?.applicant?.registered_date && moment(storeData?.applicant?.registered_date).format('DD-MM-YYYY')}</span>
                            </div>

                            <div className="col-span-2 mb-4">
                              <label>Registered Status</label>
                              <span className='profile-view'>{storeData?.applicant?.registered_status}</span>
                            </div>
                            
                            <div className="col-span-2 mb-4">
                              <label>Aadhar No</label>
                              <span>{storeData?.applicant?.aadhar_no}</span>
                            </div>

                            <div className="col-span-2 mb-4">
                              <label>Profile Status</label>
                              <span>
                                <div className="type-wrpr max-sm:flex max-sm:justify-center">
                                  <img
                                  className="type-icon hot" 
                                  src={
                                  storeData?.applicant?.registered_status === "YES" ? REG : 
                                  storeData?.applicant?.lead_status === "HOT" ? HOT : 
                                  storeData?.applicant?.lead_status === "WARM" ? WARM : 
                                  storeData?.applicant?.lead_status === "COLD" && COLD
                                  }
                                  width="" 
                                  height="" 
                                  alt="" 
                                  />
                                  <span 
                                  style={{
                                  marginLeft:'5px',
                                  color: storeData?.applicant?.registered_status === "YES" && "#1C4C3B",
                                  fontWeight: 600,
                                  marginBottom:0
                                  }}>
                                    {
                                      storeData?.applicant?.registered_status === "YES" ? "REGISTERED" : 
                                      storeData?.applicant?.lead_status === "HOT" ? "HOT" : 
                                      storeData?.applicant?.lead_status === "WARM" ? "WARM" : 
                                      storeData?.applicant?.lead_status === "COLD" && "COLD"
                                    }
                                  </span>
                                </div>
                              </span>
                            </div>

                            <div className="col-span-2 mb-4">
                              <label>Prospect Status</label>
                              <span>{storeData?.applicant?.status_of_prospect?.name}</span>
                            </div>

                            <div className="col-span-2 mb-4">
                              <label>Qualification</label>
                              <span>{storeData?.applicant?.qualification?.name}</span>
                            </div>
                            
                            <div className="col-span-2 mb-4">
                              <label>Passport No</label>
                              <span>{storeData?.applicant?.passport_no}</span>
                            </div>

                            <div className="col-span-2 mb-4">
                              <label>Passport Status</label>
                              <span className='profile-view'>{storeData?.applicant?.passport_status}</span>
                            </div>

                            <div className="col-span-6 mb-4">
                              <label>Notes</label>
                              <span className='profile-view'>{storeData?.applicant?.notes}</span>
                            </div>                            

                      </div>

                      <div className="col-md-12 text-center big-fonts profile-name-phone-wrpr" />

                      <div className='grid grid-cols-10 w-full max-lg:grid-cols-6 max-md:grid-cols-4 max-sm:grid-cols-2 max-sm:text-center'>
                          <div className="col-span-2 mb-4">
                            <label>Spouse Name</label>
                            <span>{storeData?.applicant?.spouse?.spouse_name}</span>
                          </div>
                          <div className="col-span-2 mb-4">
                            <label>Spouse Occupation</label>
                            <span>{storeData?.applicant?.spouse?.occupation}</span>
                          </div>

                          <div className="col-span-2 mb-4">
                            <label>Spouse Date of Birth</label>
                            <span>{storeData?.applicant?.spouse?.dob && moment(storeData?.applicant?.spouse?.dob).format('DD-MM-YYYY')}</span>
                          </div>

                          <div className="col-span-2 mb-4">
                            <label>Spouse Age</label>
                            <span>{calculateAge(storeData?.applicant?.spouse?.dob)}</span>
                          </div>

                          <div className="col-span-2 mb-4">
                            <label>Spouse Aadhar No</label>
                            <span>{storeData?.applicant?.aadhar_no}</span>
                          </div>

                          <div className="col-span-2 mb-4">
                            <label>Spouse Qualification</label>
                            <span>{storeData?.applicant?.qualification?.name}</span>
                          </div>

                      </div>

                    </div>
                  </div>
                </div>

                <div className="card" >
                  <div className="card-header">
                    <a className="collapsed card-link"  href="" style={{pointerEvents:'none'}}>
                    Assigning Details
                    </a>
                  </div>

                  <div id="appointment_any" className="collapse show">
                    <div className='flex gap-10 mx-5 my-10'>
                      <div className='w-full grid grid-cols-12 gap-5'>
                        {
                          storeData?.applicant?.assign_to_counsellors?.map((item,index)=>(
                            <Fragment key={index}>
                                <div className='col-span-3 max-xl:col-span-4 max-lg:col-span-6 max-sm:col-span-12'>
                                  <Card
                                  className="border-none"
                                  >
                                    <Card.Body>
                                        <div style={{display:'flex', alignItems:'center'}}>
                                          <img
                                          style={{width:'55px', height:'55px', borderRadius:'50%' , marginLeft:'10px'}}
                                          src={item?.user?.userdetail?.image ?  `${item?.user?.userdetail?.image}`:`${require('../../assets/images/profile-avatar.jpg')}`} 
                                          alt="profile"
                                          />
                                          <div>
                                            <div style={{marginBottom:'0px', marginLeft:'10px', fontSize:'14px', fontWeight:500}}>
                                              {item?.user?.userdetail?.firstname} {item?.user?.userdetail?.lastname}
                                            </div>
                                            <div style={{marginBottom:'0px', marginLeft:'10px', fontSize:'12px', fontWeight:400}}>
                                              &nbsp;{item?.role?.label}
                                            </div>
                                            <div style={{marginBottom:'0px', marginLeft:'10px', fontSize:'12px', fontWeight:400}}>
                                              &nbsp;{moment(item?.createdAt).format('Do MMM YYYY')}
                                            </div>
                                          </div>
                                        </div>
                                    </Card.Body>
                                  </Card>
                                </div>
                            </Fragment>
                        ))}
                    </div>
                    </div>	
                  </div>
                  
                  
                </div>

                <div className="card" >
                  <div className="card-header">
                    <a className="collapsed card-link"  href="" style={{pointerEvents:'none'}}>
                      Educational Details
                    </a>
                  </div>
                  <div id="appointment_any" className="collapse show" data-parent="#accordion">										
                      <div className="card-body">
                          <ul className="nav nav-tabs">
                            <li className="nav-item">
                              <a className="nav-link active" data-toggle="tab" href="#eduprimaryApplicant">Primary Applicant</a>
                            </li>
                            <li className="nav-item spouse-tab spouse-tab active">
                              <a className="nav-link" data-toggle="tab" href="#eduspouse">Spouse</a>
                            </li>
                            
                          </ul>
                          <div className="tab-content">
                          
                          <div id="eduprimaryApplicant" className="tab-pane active mt-5">
                          {
                              storeData?.educationals?.map((item,index)=>{
                                if(item.spouse_enabled === "NO")
                                  return(
                                    <Fragment key={index}>
                                      <div className="row details-page-wrpr">
                                        <div className="col-md-6 col-sm-12 mb-4">
                                          <div className="row">
                                            <div className="col-md-6">
                                              <label>Degree:</label>
                                              <span>{item?.qualification?.name}</span>
                                            </div>
                                            <div className="col-md-6">
                                              <label>Marks Obtained:</label>
                                              <span>{item?.mark_percentage}%</span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6 mb-4">
                                          <div className="row">
                                            <div className="col-md-4">
                                              <label>University / Board:</label>
                                              <span>{item?.university}</span>
                                            </div>
                                            <div className="col-md-4">
                                              <label>Duration Years:</label>
                                              <span>{item?.duration_years}</span>
                                            </div>                                      
                                            <div className="col-md-4">
                                              <label>Duration Years:</label>
                                              <span>{item?.year_and_month_ofpass}</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Fragment>
                                  )
                              })}
                          </div>
                          
                          <div id="eduspouse" className="tab-pane mt-5">
                            {
                              storeData?.educationals?.map((item,index)=>{
                                if(item.spouse_enabled === "YES")
                                return(
                                  <Fragment key={index}>
                                    <div className="row details-page-wrpr">
                                      <div className="col-md-6 col-sm-12 mb-4">
                                        <div className="row">
                                          <div className="col-md-6">
                                            <label>Degree:</label>
                                            <span>{item?.qualification?.name}</span>
                                          </div>
                                          <div className="col-md-6">
                                            <label>% of Marks Obtained:</label>
                                            <span>{item?.mark_percentage}%</span>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-6 mb-4">
                                        <div className="row">
                                          <div className="col-md-4">
                                            <label>University / Board:</label>
                                            <span>{item?.university}</span>
                                          </div>
                                          <div className="col-md-4">
                                            <label>Duration Years:</label>
                                            <span>{item?.duration_years}</span>
                                          </div>                                      
                                          <div className="col-md-4">
                                            <label>Duration Years:</label>
                                            <span>{item?.year_and_month_ofpass}</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Fragment>
                                )
                            })}
                          </div>
                          
                          
                        </div>
                    </div>
                  </div>
                </div>

                <div className="card" >
                  <div className="card-header">
                  <a className="collapsed card-link"  href="" style={{pointerEvents:'none'}}>
                    Work Experience	
                  </a>
                  
                  </div>
                  <div id="appointment_any" className="collapse show" data-parent="#accordion">
                      <div className="card-body">
                        <ul className="nav nav-tabs">
                          <li className="nav-item">
                            <a className="nav-link active" data-toggle="tab" href="#workexpprimaryApplicant">Primary Applicant</a>
                          </li>
                          <li className="nav-item spouse-tab spouse-tab active">
                            <a className="nav-link" data-toggle="tab" href="#workexpspouse">Spouse</a>
                          </li>
                        </ul>
                          
                          <div className="tab-content">
                          
                          <div id="workexpprimaryApplicant" className="tab-pane active mt-5">
                            {
                                storeData?.experiences?.map((item,index)=>{
                                  return(
                                    item?.spouse_enabled === "NO" &&
                                  <Fragment key={index}>
                                    <div className="row details-page-wrpr">
                                        <div className="col-md-6 col-sm-12 mb-4">
                                          <div className="row">
                                            <div className="col-md-6">
                                              <label>Employer’s Name:</label>
                                              <span>{item?.employers_name}</span>
                                            </div>
                                            <div className="col-md-6">
                                              <label>Position held:</label>
                                              <span>{item?.position_held}</span>
                                            </div>
                                            
                                          </div>
                                        </div>
                                        <div className="col-md-6 mb-4">
                                          <div className="row">
                                            <div className="col-md-6">
                                              <label>Experience Duration:</label>
                                              <span>{item?.exp_duration}</span>
                                            </div>
                                            <div className="col-md-6">
                                              <label>Total years and Months:</label>
                                              <span>{item?.total_years_and_months}</span>
                                            </div>
                                            
                                          </div>
                                        </div>
                                    </div>
                                  </Fragment>
                            )})}
                          </div>
                          
                          <div id="workexpspouse" className="tab-pane mt-5">
                          {
                                storeData?.experiences?.map((item,index)=>{
                                  return(
                                    item?.spouse_enabled === "YES" &&
                                  <Fragment key={index}>
                                    <div className="row details-page-wrpr">
                                        <div className="col-md-6 col-sm-12 mb-4">
                                          <div className="row">
                                            <div className="col-md-6">
                                              <label>Employer’s Name:</label>
                                              <span>{item?.employers_name}</span>
                                            </div>
                                            <div className="col-md-6">
                                              <label>Position held:</label>
                                              <span>{item?.position_held}</span>
                                            </div>
                                            
                                          </div>
                                        </div>
                                        <div className="col-md-6 mb-4">
                                          <div className="row">
                                            <div className="col-md-6">
                                              <label>Experience Duration:</label>
                                              <span>{item?.exp_duration}</span>
                                            </div>
                                            <div className="col-md-6">
                                              <label>Total years and Months:</label>
                                              <span>{item?.total_years_and_months}</span>
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                  </Fragment>
                            )})}
                          </div>
                          
                          
                          </div>
                        </div>
                  </div>
                  
                </div>

                <div className="card" >
                    <div className="card-header">
                      <a className="collapsed card-link"  href="" style={{pointerEvents:'none'}}>
                        English Proficiency {storeData?.applicant?.englishpro === "NO" && <span className="font-bold text-yellow-500">- IELTS Not Started</span>} 
                        {(storeData?.applicant?.ielts_status === "COMPLETED" && storeData?.applicant?.englishpro === "YES") && <span className="font-bold text-green-500">- IELTS Completed</span>}
                      </a>
                    </div>
                    <div id="appointment_any" className="collapse show" data-parent="#accordion">
                        <div className="card-body">
                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                  <a className="nav-link active" data-toggle="tab" href="#englishproficiencyapplicant">Primary Applicant</a>
                                </li>
                                <li className="nav-item spouse-tab spouse-tab active">
                                  <a className="nav-link" data-toggle="tab" href="#englishproficiencyspouse">Spouse</a>
                                </li>
                            </ul>
                            <div className="tab-content">
                                <div id="englishproficiencyapplicant" className="tab-pane active mt-3">
                                  <div className='grid grid-cols-12 gap-6 max-[1670px]:grid-cols-10 max-[1440px]:grid-cols-8 max-[1170px]:grid-cols-6 max-[929px]:grid-cols-4 max-[609px]:grid-cols-2'>
                                  {
                                      storeData?.english_proficiency?.map((item,index)=>(
                                        item?.spouse_enabled === "NO" &&
                                        <div key={index} className='col-span-2 flex flex-col shadow-lg rounded-xl p-4 text-[14px]'>
                                            <div className='flex justify-between'><div className='text-blue-500 font-semibold'>{item?.exam_type}</div></div>
                                            <div className='flex justify-between'>Over All: <div className='font-semibold'>{item?.overall}</div></div>
                                            <div className='flex justify-between'>Reading: <div className='font-semibold'>{item?.reading}</div></div>
                                            <div className='flex justify-between'>Listing: <div className='font-semibold'>{item?.listing}</div></div>
                                            <div className='flex justify-between'>Writing: <div className='font-semibold'>{item?.writing}</div></div>
                                            <div className='flex justify-between'>Speaking: <div className='font-semibold'>{item?.speaking}</div></div>                                      
                                            <div className='flex justify-between'>Exam Date: <div className='font-semibold'>{moment(item?.exam_date).format("DD-MM-YYYY")}</div></div>                                      
                                            <div className='flex justify-between'>Expiry Date: <div className='font-semibold'>{moment(item?.expiry_date).format("DD-MM-YYYY")}</div></div>
                                        </div>
                                  ))}
                                  </div>
                                </div>
                                
                                <div id="englishproficiencyspouse" className="tab-pane mt-5">
                                {
                                      storeData?.experiences?.map((item,index)=>(
                                        <div className='grid grid-cols-12 gap-6 max-[1670px]:grid-cols-10 max-[1440px]:grid-cols-8 max-[1170px]:grid-cols-6 max-[929px]:grid-cols-4 max-[609px]:grid-cols-2'>
                                        {
                                            storeData?.english_proficiency?.map((item,index)=>(
                                              item?.spouse_enabled === "YES" &&
                                              <div key={index} className='col-span-2 flex flex-col shadow-lg rounded-xl p-4 text-[14px]'>
                                                  <div className='flex justify-between'><div className='text-blue-500 font-semibold'>{item?.exam_type}</div></div>
                                                  <div className='flex justify-between'>Over All: <div className='font-semibold'>{item?.overall}</div></div>
                                                  <div className='flex justify-between'>Reading: <div className='font-semibold'>{item?.reading}</div></div>
                                                  <div className='flex justify-between'>Listing: <div className='font-semibold'>{item?.listing}</div></div>
                                                  <div className='flex justify-between'>Writing: <div className='font-semibold'>{item?.writing}</div></div>
                                                  <div className='flex justify-between'>Speaking: <div className='font-semibold'>{item?.speaking}</div></div>                                      
                                                  <div className='flex justify-between'>Exam Date: <div className='font-semibold'>{moment(item?.exam_date).format("DD-MM-YYYY")}</div></div>                                      
                                                  <div className='flex justify-between'>Expiry Date: <div className='font-semibold'>{moment(item?.expiry_date).format("DD-MM-YYYY")}</div></div>
                                              </div>
                                        ))}
                                        </div>
                                  ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="card">
                  <div className="card-header">
                    <a className="collapsed card-link" href="" style={{pointerEvents:'none'}}>
                      Additional Information
                    </a>
                  </div>
                  <div id="appointment_any" className="collapse show" data-parent="#accordion">
                      <div className="row details-page-wrpr mt-4 px-10">
                          {
                            storeData?.additional_informations?.map((item,index)=>(
                              <Fragment key={index}>
                                <div className="col-md-12">
                                  <div className="row ai-card mb-4">
                                    <div className="col-md-12">
                                      <label>{item?.additional_information_question?.question}</label>
                                      <hr/>
                                    </div>
                                    
                                    <div className="col-md-12">
                                      <div className="row">
                                        <div className="col-md-2">
                                          <label>Status</label>
                                          <span>{item?.answer_status}</span>
                                        </div>
                                        <div className="col-md-2">
                                          <label>Country:</label>
                                          <span>{item?.country?.name}</span>
                                        </div>
                                        <div className="col-md-2">
                                          <label>Year:</label>
                                          <span>{item?.year}</span>
                                        </div>
                                        {/* <div className="col-md-3">
                                          <label>Currant Status:</label>
                                          <span>{item?.current_status}</span>
                                        </div> */}
                                      </div>
                                    </div>
                                    
                                    <div className="col-md-6 mb-2">
                                      &nbsp;
                                    </div>
                                    
                                  </div>
                                </div>
                              </Fragment>
                            ))
                          }														
                      </div>
                  </div>
                  
                </div>
                
                <div className="card">
                      <div className="card-header">
                      <a className="collapsed card-link" href="" style={{pointerEvents:'none'}}>
                        Document Details
                      </a>
                      </div>
                      <div id="appointment_any" className="collapse show" data-parent="#accordion">
                          <div className="mx-4 my-4">
                            <div className="grid grid-cols-12 gap-6">
                              {
                                storeData?.fileuploads?.map((item,index)=>(
                                  <div className="col-span-3 max-[1346px]:col-span-4 max-lg:col-span-6 max-[727px]:col-span-12  bg-white shadow-lg rounded-xl" key={index}>
                                    <div className='p-3 grid grid-cols-12 items-center'>
                                      <div className='col-span-9'>
                                        <div className='text-[12px] text-[blue] font-bold'>{item?.document ? decode(item?.document?.document_name): item?.reason}</div>
                                        <div className='text-[12px]'>{item?.file?.substring(item?.file?.lastIndexOf('/') + 1)}</div>
                                      </div>
                                      <div className='col-span-3 flex justify-end gap-3'>
                                        <Tooltip
                                          content={"Document Preview"}
                                          trigger="hover"
                                          color="primary"
                                        >
                                            <button
                                              onClick={()=>handleViewPDF(item?.file)}
                                              className='flex items-center px-2 py-1 border-[1px] rounded-md'
                                              style={{background:'none'}}
                                            >
                                                <i className='icon-eye4 text-[12px] text-black'></i>
                                            </button> 
                                        </Tooltip> 
                                      </div>
                                    </div>
                                  </div>
                              ))}
                            </div>
                            {
                              (store?.appAllData?.applicant?.assessment_old && (oldDocuments?.data?.data?.length > 0)) &&
                              <div className="mx-4 my-4">
                                  <div className="grid grid-cols-12 gap-6">
                                      {oldDocuments?.data?.data?.map((item,index)=>(
                                          <div className="col-span-2 max-[1346px]:col-span-4 max-lg:col-span-6 max-[727px]:col-span-12  bg-white shadow-lg rounded-xl" key={index}>
                                              <div className='p-3 grid grid-cols-12 items-center'>
                                                  <div className='col-span-9'>
                                                  <div className='text-[12px] text-[black] font-medium'>{decode(item?.name)}</div>
                                                  </div>
                                                  <div className='col-span-3 flex justify-end gap-3'>
                                                      <button
                                                          onClick={()=>downloadOldFile(item?.url)}
                                                          className='flex items-center px-2 py-1 border-[1px] rounded-md'
                                                          style={{background:'none'}}
                                                      >
                                                          <i className='icon-eye4 text-[12px] text-black'></i>
                                                      </button> 
                                                  </div>
                                              </div>
                                          </div>
                                      ))}
                                  </div>
                              </div>
                          }
                          </div>
                      </div>
                </div>

                <div className="card">
                  <div className="card-header">
                      <a className="collapsed card-link" href="" style={{pointerEvents:'none'}}>
                        Intended College /Program
                      </a>
                  </div>
                  <div id="appointment_any" className="collapse show" data-parent="#accordion">
                      <div className="row details-page-wrpr mt-4 px-10">
                        {
                          storeData?.intended_programs?.map((item,index)=>(
                            <Fragment key={index}>
                              <div className="col-md-12">
                                <Badge.Ribbon className="ribbon-badge" text={`Priority ${item?.priority}`} placement="end" color={item?.priority === 1 ? 'green' : item?.priority === 2 ? 'blue' : 'purple' } >
                                  <div className="col-md-12">
                                      <div className="row ai-card p-4 mb-4">
                                        <div className="col-md-4">
                                          <label>College/University</label>
                                          <span>{item?.college?.name}</span>
                                        </div>                              
                                        <div className="col-md-4">
                                          <label>Program Name</label>
                                          <span>
                                            {
                                              item?.program?.programlink ?
                                              <a href={item?.program?.programlink} className='text-[blue]' target='_blank'>
                                                {item?.program?.name}
                                              </a> :
                                              (item?.program?.name)
                                            }
                                          </span>
                                        </div>
                                        <div className="col-md-2">
                                          <label>Program Code</label>
                                          <span>{item?.program?.program_code}</span>
                                        </div>                              
                                        <div className="col-md-2">
                                          <label>Intake</label>
                                          <span>{item?.intake?.intake}</span>
                                        </div>
                                        <div className="col-md-2  mt-3">
                                          <label>Program Duration</label>
                                          <span>{item?.program?.duration && `${item?.program?.duration} Year`}</span>
                                        </div>
                                        <div className="col-md-2 mt-3">
                                          <label>Placement</label>
                                          <span>{item?.placement}</span>
                                        </div>
                                        <div className="col-md-2 mt-3">
                                          <label>Program Coordinator Status</label>
                                          <span>{item?.status}</span>
                                        </div>
                                        <div className="col-md-2 mt-3">
                                          <label>Student Status</label>
                                          <span>{item?.app_status}</span>
                                        </div>
                                        <div className="col-md-2 mt-3">
                                          <label>Created By</label>
                                          <span>{item?.createdBy?.userdetail?.firstname} {item?.createdBy?.userdetail?.lastname}</span>
                                        </div>
                                        <div className="col-md-2 mt-3">
                                          <label>Updated By</label>
                                          <span>{item?.updatedBy?.userdetail?.firstname} {item?.updatedBy?.userdetail?.lastname}</span>
                                        </div>
                                        <div className="col-md-2 mt-3">
                                          <label>Application Status</label>
                                          <span>{item?.appstaffstatuss?.name}</span>
                                        </div>
                                        <div className='col-md-2 mt-3'>
                                          <label>Confirm Through</label>
                                          <span>
                                            {item?.confirm_via === "WHATSAPP" ? 
                                              <div className='flex items-center gap-2 text-[green]'><IconBrandWhatsapp size={20} color='green' /> Whatsapp</div>:
                                              item?.confirm_via === "EMAIL" &&
                                              <div className='flex items-center gap-2 text-[red]'><IconMail size={20} color='red' /> Email</div>
                                            }
                                          </span>
                                        </div>
                                        {
                                          item?.notes &&
                                          <div className="col-md-12 mt-3">
                                            <label>Notes</label>
                                            <span>{item?.notes}</span>
                                          </div>
                                        }

                                      <div className='col-md-12 mt-4'>
                                          <div className="grid grid-cols-12 gap-6">
                                            {
                                              item?.programdocs?.map((items, indexes) => (
                                              <div className="col-span-3 max-[1346px]:col-span-4 max-lg:col-span-6 max-[727px]:col-span-12  bg-white shadow-lg rounded-xl" key={indexes}>
                                                <div className='p-3 grid grid-cols-12 items-center'>
                                                  <div className='col-span-9'>
                                                    <div className='text-[12px] text-[blue] font-bold'>{items?.type === "OFFER" ? "Offer Letter" : "Visa"}</div>
                                                    <div className='text-[12px]'>{items.name}</div>
                                                  </div>
                                                  <div className='col-span-3 flex justify-end gap-3'>
                                                    <Tooltip
                                                      content={"Document Preview"}
                                                      trigger="hover"
                                                      color="primary"
                                                    >
                                                        <button
                                                          onClick={()=>handleViewPDF(items?.file)}
                                                          className='flex items-center px-2 py-1 border-[1px] rounded-md'
                                                          style={{background:'none'}}
                                                        >
                                                            <i className='icon-eye4 text-[12px] text-black'></i>
                                                        </button> 
                                                    </Tooltip>
                                                  </div>
                                                </div>
                                              </div>
                                            ))}
                                          </div>
                                      </div>

                                      </div>
                                  </div>
                                </Badge.Ribbon>
                              </div>
                            </Fragment>
                        ))}
                      </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-header">
                  <a className="collapsed card-link" href="" style={{pointerEvents:'none'}}>
                    Overseas Education History, If any
                  </a>
                  
                  </div>
                  <div id="appointment_any" className="collapse show" data-parent="#accordion">
                    <div className="row details-page-wrpr mt-4 px-10">

                        {
                          storeData?.overseas_education_historys?.map((item,index)=>(
                            <Fragment key={index}>
                              <div className="col-md-12">
                                <div className="row ai-card mb-4">			
                                  <div className="col-md-6 mb-4">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <label>Country:</label>
                                        <span>{item?.country?.name}</span>
                                      </div>
                                      <div className="col-md-6">
                                        <label>College/ University:</label>
                                        <span>{item?.college}</span>
                                      </div>
                                      
                                    </div>
                                  </div>
                                  <div className="col-md-6 mb-4">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <label>Program:</label>
                                        <span>{item?.program_name}</span>
                                      </div>
                                      <div className="col-md-6">
                                        <label>Existing/ Closed:</label>
                                        <span>{item?.existing_or_closed}</span>
                                      </div>
                                      
                                    </div>
                                  </div>
                                  <div className="col-md-6 mb-4">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <label>Visa Term:</label>
                                        <span>{item?.visa_term}</span>
                                      </div>
                                      <div className="col-md-6">
                                        <label>Return Date:</label>
                                        <span>{moment(item?.return_date).format('MMM DD YYYY')}</span>
                                      </div>
                                    </div>
                                  </div>
                                  
                                  <div className="col-md-6 mb-4">
                                    &nbsp;
                                  </div>
                                </div>
                              </div>
                            </Fragment>
                        ))}

                            
                    </div>
                  </div>
                  
                </div>

                <div className="card">
                  <div className="card-header">
                    <a className="collapsed card-link" href="" style={{pointerEvents:'none'}}>
                      Academic/ Profile/ Employment Gap History, If Any
                    </a>
                  </div>
                  <div id="appointment_any" className="collapse show" data-parent="#accordion">
                      <div className="row details-page-wrpr mt-4 px-10">
                        {
                          storeData?.academic_gap_historys?.map((item,index)=>(
                            <Fragment key={index}>
                              <div className="col-md-12">
                                <div className="row ai-card mb-4">			
                                  <div className="col-md-12 mb-4">
                                      <label>State the period and reason for the gap:</label>
                                      <span>{item?.reason_for_the_gap}</span>
                                  </div>
                                </div>
                              </div>
                            </Fragment>
                        ))}
                  
                      </div>
                  </div>
                </div>
                
                <div className="card">
                  <div className="card-header">
                    <a className="collapsed card-link" href="" style={{pointerEvents:'none'}}>
                      Travel History, If Any
                    </a>
                  </div>
                  <div id="appointment_any" className="collapse show" data-parent="#accordion">
                    <div className=''>
                      <div className="row details-page-wrpr mt-4 px-10">
                      {
                        storeData?.travelhistories?.map((item,index)=>(
                          <Fragment key={index}>
                                <div className="col-md-12">
                                  <div className="row ai-card mb-4">		
                                    <div className="col-md-6 mb-4">
                                      <div className="row">
                                        <div className="col-md-6">
                                          <label>Country:</label>
                                          <span>{item?.country?.name}</span>
                                        </div>
                                        <div className="col-md-6">
                                          <label>Duration</label>
                                          <span>{item?.dur_from} - {item?.dur_to}</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6 mb-4">
                                      <div className="row">
                                        <div className="col-md-6">
                                          <label>Purpose:</label>
                                          <span>{item?.purpose}</span>
                                        </div>
                                        <div className="col-md-6">
                                          <label>Nature/Type of Visa:</label>
                                          <span>{item?.visa_type}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>                      
                          </Fragment>
                      ))}
                      </div>	
                    </div>
                  </div>
                  
                </div>

                <div className="card">
                  <div className="card-header">
                    <a className="collapsed card-link" href="" style={{pointerEvents:'none'}}>
                      Visa Data
                    </a>
                  </div>
                  <div id="appointment_any" className="collapse show" data-parent="#accordion" style={{marginTop:'50px'}}>
                    <div className="row details-page-wrpr">
                    {
                      storeData?.applicant?.visadatas?.map((item,index)=>(
                        <Fragment key={index}>
                            <div className="col-md-6">
                              <div className="row ai-card" style={{marginLeft:'2px', marginRight:'2px'}}>		
                                <div className="col-md-12 mb-4">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <label>{item?.name} (Yes/No):</label>
                                      <span>
                                        {
                                          (item?.date && item?.radio === "NO") ? "-" : item?.radio === "YES" ? "Yes" : "No"
                                        }
                                      </span>
                                    </div>
                                    <div className="col-md-6">
                                      <label>{item?.name} Date</label>
                                      <span>
                                        {
                                          item?.date ? moment(item?.date).format('MMM DD YYYY') : "-"
                                        }
                                      </span>
                                    </div>
                                    
                                  </div>
                                </div>
                              </div>
                            </div>                      
                        </Fragment>
                    ))}
                    </div>	
                  </div>
                  
                </div>

              </div>
            </div>
        }

    </div>
  )
}

export default VisaStaffDetailPage