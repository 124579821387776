/* eslint-disable jsx-a11y/anchor-is-valid */
// import { decode } from 'html-entities'
import React, { Fragment, useEffect, useState } from 'react'
import { Empty } from 'antd';
import { decode } from 'html-entities';
import LoaderCommon from '../Common/LoaderCommon';
import { Link, useNavigate } from 'react-router-dom';
import callCenterQueries from '../../queries/callCenterQueries';
import { useForm } from 'react-hook-form';
import formatIndianPhoneNumber from '../../utils/formatMobileNumber';
import toast from 'react-hot-toast';

const CallCenterCounselor = () => {

    const navigate = useNavigate()

    const [result, setResult] = useState([])

    const dropdown = callCenterQueries.useCounselorListDropdown(
        (res)=>{
        }
    )

    useEffect(() => {
        dropdown.mutateAsync()
    }, []);

    const getApplicant = callCenterQueries.useCallCenterCounselorSearch(
        (res)=>{
            if(res?.message === "success"){
                setResult(res?.data)
            }
        }
    )

    const { register, handleSubmit } = useForm({});

    const onSearch =(data)=>{
        if(!data?.office || !data?.country){
            toast.error("Please Select Office & Country")
            return
        }
        getApplicant.mutateAsync(data)
    }

    return (
        <Fragment>
            
            <form onSubmit={handleSubmit(onSearch)}>
                <div className='grid grid-cols-12 gap-4'>
                    <div className="col-span-2 flex flex-col">
                        <div className='text-[12px] h-[25px]'>Country</div>
                        <select
                        {...register("country")}
                        name="country"
                        type="text"
                        className="bg-white border border-solid border-gray-300 rounded-md w-full !min-h-[40px] pl-[10px] pr-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0"
                        >
                            <option value="">Select Country</option>
                            {
                                dropdown?.data?.country?.map((item, index)=>(
                                    <option key={index} value={item?.id}>{item?.name}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className="col-span-2 flex flex-col">
                        <div className='text-[12px] h-[25px]'>Office</div>
                        <select
                        {...register("office")}
                        name="office"
                        type="text"
                        className="bg-white border border-solid border-gray-300 rounded-md w-full !min-h-[40px] pl-[10px] pr-[10px] text-[12px] focus:border-none outline-blue-500 outline-offset-0"
                        >
                            <option value="">Select Office</option>
                            {
                                dropdown?.data?.office?.map((item, index)=>(
                                    <option key={index} value={item?.id}>{item?.name}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className='col-span-2 flex flex-col'>
                        <div className='h-[25px]'></div>
                        <button type="submit" className="btn-customized-large w-[120px] h-[38px] rounded-md">
                            Search
                        </button>
                    </div>
                </div>
            </form>

            <div className="search-results-count-wrpr mt-3">
                {
                result?.length > 0 ?
                `Search Results - ${result?.length}` :
                `O results found`
                }
            </div>

            <div className="">
                {getApplicant.isLoading ? 
                <LoaderCommon />:
                result?.length > 0 ?
                    <div className="table-wrpr">
                        <div className="table-section">
                            <div className="row">
                                <div className="col-md-12">
                                    <table className="table prospect-table mb-0">
                                        <thead>
                                            <tr className='text-[13px]'>
                                                <th width="50">#</th>
                                                <th>Counselor Name</th>
                                                <th>Counselor Phone</th>
                                                <th>Counselor Email</th>
                                                <th className='text-center'>Active leads for the selected country</th>
                                                <th className='text-center'>Total fee paid for selected country</th>
                                                <th className='text-center'>Total active clients</th>
                                                <th className='text-center'>Score</th>
                                            </tr>
                                        </thead>
                                        <tbody className="ui-sortable">
                                            {result?.map((item, index) => {
                                                const formattedNumber = formatIndianPhoneNumber(item?.phone);
                                                return(
                                                    <tr key={index} className="ui-sortable-handle text-[13px]">
                                                        <td>{index + 1}</td>
                                                        <td>{item?.name}</td>
                                                        <td className='font-bold text-[14px] text-blue-800'>{formattedNumber}</td>
                                                        <td>{item?.email}</td>
                                                        <td className='text-center'>{item?.countryAssigned}</td>
                                                        <td className='text-center'>{item?.countryAssignedFeePaid}</td>
                                                        <td className='text-center'>{item?.totalAssigned}</td>
                                                        <td className='text-center'>{item?.score}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>:
                    <div className='mt-24 mb-24 flex items-center flex-col'>
                        <Empty
                        description={<div className="text-sm text-blue-700" style={{fontFamily: "var(--primary-font)"}}>Counselor not found!</div>}
                        />
                    </div>
                }
            </div>

        </Fragment>
    )
}

export default CallCenterCounselor