import React, { Fragment, useState } from 'react'
import authQueries from '../../queries/authQueries'
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { generateValidationSchema } from './validationSchema';
import { Modal } from '@nextui-org/react';
import moment from 'moment';
import { useApi } from './ApiProvider';
import AssignModal from './AssignModal';

const RegistrationModal = ({item}) => {

    const [isChanged, setIsChanged] = useState(false)

    const inputFields = [
        {
            name: 'comment',
            required: true,
            min: 10,
            max: 256,
            label: 'Comment'
        },
        {
            name: 'date',
            required: true,
            label: 'Date'
        }
    ]

    const [open, setOpen] = useState(false)

    const { setApiDataAndNotify } = useApi()

    const { register, setValue, handleSubmit, formState:{ errors }} = useForm({
        resolver: yupResolver(generateValidationSchema(inputFields))
    });

    const handleOpen =()=> {
        setOpen(true)
    }

    const handleClose =()=> {
        setOpen(false)
        setIsChanged(false)
        if(isChanged){
            setApiDataAndNotify(new Date())
        }
    }

    const onSubmit = (data) => {
        const dataValues = {    
            id: item?.id,
            comment: data.comment,
            date: data.date
        }

        const statusData = {
            applicant_id: item?.id,
            prospects_id: 33,
            comments: data.comment,
            status: "ACTIVE"
        }

        addProspect.mutateAsync(statusData);
        markRegistration.mutateAsync(dataValues)
    }

    const addProspect = authQueries.useProStatusAdd(
        (response) => {
            setValue('comment', '')
            setValue('date', moment('yyyy-mm-dd'))
        }
    );

    const markRegistration = authQueries.useApplicationMutationMarkRegistration(
        (response) => {
            setIsChanged(true)
        }   
    )

    const reAssignFinder = (item) => {
        let arr = [6,3,7,8,9]
        if(item?.visatype?.sop){
            arr.push(22)
        }
        if(item?.visatype?.doc){
            arr.push(5)
        }
        return arr
    }

  return (
    <Fragment>
        <button onClick={handleOpen} 
        className="btn-customized">Mark Registration</button>
        <Modal
        scroll
        blur
        closeButton
        width="550px"
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        open={open}
        onClose={handleClose}
        >
            <Modal.Header />
            <Modal.Body>
                {
                    !isChanged ?
                    <div>
                        <form onSubmit={handleSubmit(onSubmit)}>                      
                            <div className="modal-body p-3">
                                <div className="form-group">
                                    <label>Registration Date:</label>
                                    <input
                                    max={moment().format('YYYY-MM-DD')}
                                    style={{height:'44px'}} 
                                    {...register('date')} 
                                    name="date" 
                                    type="date" 
                                    className="form-control"
                                    />
                                    <div style={{marginTop:'5px'}}>{errors.comment && <span style={{color:"red", fontSize:'13px'}}>{errors?.date?.message}</span>}</div>
                                </div>
                                <div className="form-group">
                                    <label >Registration Comment:</label>
                                    <textarea {...register('comment')} name="comment" className="form-control" placeholder="Enter Comment" style={{ minHeight: '100px' }}></textarea>
                                    <div style={{marginTop:'5px'}}>{errors.comment && <span style={{color:"red", fontSize:'13px'}}>{errors?.comment?.message}</span>}</div>
                                </div>
                                <button className='btn-customized-large w-full' type="submit">
                                    Mark Registration
                                </button>
                            </div>
                        </form>
                    </div>:
                    <div className='w-full flex flex-col items-center justify-center gap-10'>
                        
                        <div className="font-sans text-gray-800 text-center">
                            <p className="text-green-600 font-semibold">
                                Application successfully registered.
                            </p>
                            <p>
                                Click <span className="text-blue-500 font-semibold">'Assign/Reassign'</span> to continue, 
                                or <span className="text-red-500 font-semibold">close</span> to proceed without assigning. 
                                <span className="text-gray-500">It can be assigned later if needed.</span>
                            </p>
                        </div>

                        
                        <div className='w-[200px]'>
                            <AssignModal
                            fontSize={"14px"}
                            className="btn-customized-large w-full"
                            item={item} 
                            roles={reAssignFinder(item)}
                            selectedRole={3} 
                            label="Assign / Reassign" 
                            noOffice={true}
                            />
                        </div>
                    </div>
                }
            </Modal.Body>
            <Modal.Footer />
        </Modal>
    </Fragment>
  )
}

export default RegistrationModal
