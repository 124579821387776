import callCenterService from "../services/callCenterService";
import { useMutation } from "react-query";

const useCallCenterApplicationSearch = (oData) => {
  return useMutation(callCenterService.getApplicant, {
    onSuccess: (data) => oData(data),
  })
}

const useCallCenterCounselorSearch = (oData) => {
  return useMutation(callCenterService.getCounselor, {
    onSuccess: (data) => oData(data),
  })
}

const useCounselorListDropdown = (oData) => {
  return useMutation(callCenterService.getCounselorListDropdown, {
    onSuccess: (data) => oData(data),
  })
}

const callCenterQueries = {
  useCallCenterApplicationSearch,
  useCallCenterCounselorSearch,
  useCounselorListDropdown
}

export default callCenterQueries