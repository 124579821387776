
import React from "react";
import Sidebar from "./Sidebar";
import SocketLogout from "../../../socket/SocketLogout";

const PageLayout = () => {
  return (
    <div>
        <SocketLogout />
        <Sidebar />    
    </div>
  );
}

export default PageLayout;
