import apiRequest from '../apis/api-request';
import { userStore } from '../store/userStore';

const accessToken = userStore.getState().user.accessToken

const secondaryRefferalService = {

    getRefferal: async (data) => {
        const page = data?.page? data.page:0
        const size = data?.size? data.size: 25
        const search = data?.search? data.search:""
        return apiRequest({
            method: "GET",
            url: `/api/secondaryrefferals?search=${search}&page=${page}&size=${size}`,
            headers: {
                Authorization: "Bearer " + accessToken
            },
        });
    },
    createRefferal: async (data) => {
        return apiRequest({
            method: "POST",
            url: `/api/secondaryrefferals`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            },
        });
    },
    updateRefferal: async (data) => {
        return apiRequest({
            method: "PUT",
            url: `/api/secondaryrefferals/${data.id}`,
            data: data,
            headers: {
                Authorization: "Bearer " + accessToken
            },
        });
    },
    deleteRefferal: async (id) => {
        return apiRequest({
            method: "DELETE",
            url: `/api/secondaryrefferals/${id}`,
            headers: {
                Authorization: "Bearer " + accessToken
            },
        });
    },
    primaryRefferalDropdown: async () => {
        return apiRequest({
            method: "GET",
            url: `/api/secondaryrefferals/dropdown`,
            headers: {
                Authorization: "Bearer " + accessToken
            },
        });
    },

}

export default secondaryRefferalService