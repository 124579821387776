import React, { Fragment, useEffect, useState } from "react";
import authQueries from '../../queries/authQueries';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Empty, Popover, Select, message } from "antd";
import { Pagination, PaginationItem } from "@mui/material";
import LoaderCommon from "./LoaderCommon";
import InputSearch from "./InputSearch";
import SelectSearch from "./SelectSearch";
import { usePaginationStore, userStore } from "../../store/userStore";
import UserData from "../../Data/UserData";
import PaginationComponent from "./PaginationComponent";
import ApplicantViewButton from "./ApplicantViewButton";
import { IconFileDescription } from "@tabler/icons-react";
import { decode } from "html-entities";
import moment from "moment";
import LeadStatus from "./LeadStatus";
import FollowUp from "./FollowUp";
import AdvancedSearch from "./AdvancedSearch";
import { useAdvancedSearch } from "../../store/advancedSearchStore";
import { useApi } from "./ApiProvider";
import DocumentUploadApplicant from "./DocumentUploadApplicant";

const FinishedClients =()=>{

    const store = userStore()

    const currentPage = usePaginationStore((state) => state.currentPage);
    const setCurrentPage = usePaginationStore((state) => state.setCurrentPage);

    const { apiData } = useApi()

    const [result, setResult] = useState([])
   
    const data = useAdvancedSearch((state) => state);

    const finishedClientsList = authQueries.useFinishedClientsListMutation(
        (res)=>{
            setResult(res)
        }
    )

    useEffect(()=>{
        finishedClientsList.mutateAsync({
            assign: UserData.find(item=>item?.role === store?.user?.role)?.name,
            ...data,
            page: currentPage,
            size: 25,
        })
    },[currentPage, data, apiData ])


    return(
        <div>
            <div className="add_clients border-none">
                <div className="row">
                    <div className="col-md-12" style={{display:'flex', alignItems:'center', gap:'4px'}}>
                        <i className="followup-icon">
                            <IconFileDescription size="30" className='text-[#7D6FAB]' />
                        </i>
                        <div style={{display:'flex', flexDirection:'column'}}>
                            <div style={{display:'flex', alignItems:'center', gap:'10px'}}>
                                <span className="text-[16px] font-medium">Visa Approved / Visa Refused / Refund Completed</span>	
                            </div>
                        </div>
                    </div>
                </div>
                <div className="add_clients_accordion_form flex flex-col mt-4">
                    <div className="card">
                        <div class="card-header"><a class="collapsed card-link" href="" style={{pointerEvents:'none'}}>My Clients</a></div>
                        <div className="p-3 flex flex-col gap-4">
                            
                            <AdvancedSearch report={true} visaapproved={true} finishReport={true} />

                            <div>
                                {
                                    finishedClientsList.isLoading ?
                                    <LoaderCommon />:
                                    (
                                        result?.data?.totalItems > 0 ?
                                        <div className="table-list-search-wrpr simple-search">
                                            <div className="search-results-count-wrpr">
                                                {
                                                result?.data?.totalItems > 0 ?
                                                `Search Results ${result?.data?.currentPage * 25 + 1}-${result?.data?.currentPage * 25 + result?.data?.items?.length} of ${result?.data?.totalItems}` :
                                                `O results found`
                                                }
                                            </div>
                                            <div className="table-wrpr mb-0">
                                                <div className="table-section">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <table className="table prospect-table">
                                                                <thead>
                                                                    <tr>
                                                                        <th width="30">#</th>
                                                                        <th>Profile Status</th>
                                                                        <th>Office</th>
                                                                        <th>Date Created</th>
                                                                        <th>Applicant Name</th>
                                                                        <th>Contact Number</th>
                                                                        <th>Visa Type</th>
                                                                        <th>Intake</th>
                                                                        <th>Reg. Date</th>
                                                                        <th>Prospect Status</th>
                                                                        <th>Visa Approved On</th>
                                                                        <th>Reffered By</th>
                                                                        <th width='150'>&nbsp;</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        result?.data?.items?.map((item, index) => {
                                                                            const application = item?.application
                                                                            return(
                                                                            <tr>
                                                                                <td className="">{(index + 1) + (result.data?.currentPage * 25)}</td>
                                                                                <td>
                                                                                    <LeadStatus item={application} />
                                                                                </td>
                                                                                <td>{application?.office?.name}</td>
                                                                                <td>{moment(application?.createdAt).format('DD-MM-YYYY')}</td>
                                                                                <td>
                                                                                    {decode(application?.applicant_name)} {decode(application?.middlename)} {decode(application?.lastname)}
                                                                                </td>
                                                                                <td>
                                                                                    <div className="email-mobile flex flex-col">
                                                                                        <div>{application?.mobile_no}</div>
                                                                                        <div>{application?.landline_no}</div>
                                                                                        <div>{application?.whatsapp_no}</div>
                                                                                    </div>
                                                                                </td>
                                                                                <td>{application?.visatype?.description}</td>
                                                                                <td>{application?.intake?.intake}</td>
                                                                                <td>
                                                                                    {application?.registered_date ? <span className="registered">{moment(application?.registered_date).format('DD-MM-YYYY')}</span> : '-'}
                                                                                </td>
                                                                                <td>{application?.status_of_prospect?.name}</td>
                                                                                <td>{moment(item?.createdAt).format('DD-MM-YYYY')}</td>
                                                                                <td>{application?.refferal?.refferal}</td>
                                                                                {
                                                                                    (store?.user?.role === "VISASTAFF"  && application?.pros_status === 17) ? 
                                                                                    <td className='grid min-w-[130px] grid-cols-12 justify-end gap-1'>
                                                                                        <div className='col-span-12'>
                                                                                            <FollowUp item={application} statusHidden={application?.pros_status === 18 ? false : true}/>
                                                                                        </div>
                                                                                        <div className='col-span-4'>
                                                                                            <ApplicantViewButton item={application} />
                                                                                        </div>
                                                                                        <div className='col-span-8'>
                                                                                            <DocumentUploadApplicant item={application} />
                                                                                        </div>
                                                                                    </td>:
                                                                                    <td className='flex justify-end gap-1'>
                                                                                        <div className='w-[130px]'>
                                                                                            <FollowUp item={application} statusHidden={application?.pros_status === 18 ? false : true}/>
                                                                                        </div>
                                                                                        <div className='w-[50px]'>
                                                                                            <ApplicantViewButton item={application} />
                                                                                        </div>
                                                                                    </td>
                                                                                }
                                                                            </tr>
                                                                        )})
                                                                    }									
                                                                </tbody>
                                                            </table>
                                                            <PaginationComponent result={result} currentPage={currentPage} setCurrentPage={setCurrentPage} />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>:
                                        <div className='mt-24 mb-24'>
                                            <Empty
                                            description={<div className="text-sm text-blue-700" style={{fontFamily: "var(--primary-font)"}}>Clients not found!</div>}
                                            />
                                        </div>
                                    )
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FinishedClients